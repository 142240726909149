import React, { useEffect, useState } from "react";
import "./template_styles.css";
import TextDiffRenderer from "../components/TextDiffRendered";

const Template2 = ({
  data,
  fontColor,
  backgroundColor,
  conflicts,
  difference,
  clickHandler,
  actionHandler,
}) => {
  const styles = {
    body: {
      fontFamily: "'Roboto', sans-serif",
      margin: 0,
      padding: 0,
      boxSizing: "border-box",
      backgroundColor: "#FFFFFF",
    },
    resumeHeader: {
      backgroundColor: "#FFFFFF",
      padding: "10px",
      textAlign: "center",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    },
    resumeHeaderH1: {
      margin: 0,
      fontSize: "36px",
      color: fontColor,
      fontWeight: "bold",
    },
    resumeHeaderP: {
      margin: "10px 0 0",
      fontSize: "18px",
      color: "#000000",
      textAlign: "justify",
      backgroundColor: backgroundColor,
      display: "inline-block",
      maxWidth: "100%",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    },
    resume: {
      display: "block",
      width: "100%",
      maxWidth: "800px",
      margin: "-20px auto 0",
      padding: "20px",
      backgroundColor: "#FFFFFF",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    },
    section: {
      marginBottom: "10px",
    },
    sectionH2: {
      borderBottom: `2px solid ${fontColor}`,
      paddingBottom: "10px",
      marginBottom: "20px",
      fontSize: "22px",
      fontWeight: "bold",
      color: fontColor,
    },
    sectionP: {
      fontSize: "16px",
      lineHeight: "1.0",
      color: "#212529",
    },
    skillsList: {
      backgroundColor: backgroundColor,
      padding: "10px",
      marginBottom: "10px",
      borderLeft: `4px solid ${fontColor}`,
    },
    personalDetailsP: {
      backgroundColor: backgroundColor,
      padding: "10px",
      marginBottom: "10px",
      borderLeft: `4px solid ${fontColor}`,
    },
    project: {
      backgroundColor: backgroundColor,
      padding: "10px",
      marginBottom: "10px",
      borderLeft: `4px solid ${fontColor}`,
    },
    job: {
      backgroundColor: backgroundColor,
      padding: "10px",
      marginBottom: "10px",
      borderLeft: `4px solid ${fontColor}`,
    },
    optionalDetailsDescription: {
      backgroundColor: backgroundColor,
      padding: "10px",
      marginBottom: "10px",
      borderLeft: `4px solid ${fontColor}`,
    },
    strong: {
      display: "flex",
    },
    h3: {
      fontWeight: "bold",
      marginBlock: "20px",
      fontSize: "1.14em",
    },
    p: {
      marginBlock: "10px",
    },
  };

  useEffect(() => {
    console.log("CV data = ", data);
    if (data) {
      setPersonalDetails({
        name: data.name,
        location: data.location,
        email: data.email,
        phone: data.phone,
        linkedin: data.linkedin,
        portfolio: data.portfolio,
      });
      setCandidateSummary(data?.summary);
      setQualification(data?.qualifications);
      setSkills(data?.skills);
      setOptionalDetails(data?.optional);
      setExperience(data?.experience);
      setProjects(data?.projects);
      console.log(data.candidate_summary);
    }
  }, [data]);
  const [personalDetails, setPersonalDetails] = useState({});
  const [candidateSummary, setCandidateSummary] = useState("");
  const [Qualification, setQualification] = useState([]);
  const [skills, setSkills] = useState([]);
  const [optionalDetails, setOptionalDetails] = useState([]);
  const [experience, setExperience] = useState([]);
  const [projects, setProjects] = useState([]);

  return (
    <div style={styles.body} className="w-[650px]">
      <div style={styles.resumeHeader}>
        <h1
          id="template-name"
          onClick={clickHandler}
          className="hover:bg-primary/10 cursor-pointer"
          style={styles.resumeHeaderH1}
        >
          {personalDetails.name}
        </h1>
        <p className="hover:bg-primary/10 " style={styles.resumeHeaderP}>
          <p
            id="template-cs"
            onClick={clickHandler}
            className="hover:bg-primary/10 cursor-pointer"
          >
            {difference &&
            difference["summary"] &&
            conflicts &&
            conflicts["summary"] === -1 ? (
              <TextDiffRenderer
                diffData={difference["summary"]}
                accept={"cs"}
                actionHandler={actionHandler}
              />
            ) : (
              candidateSummary
            )}
          </p>
        </p>
      </div>
      <div style={styles.resume}>
        <div className="left-column">
          {/* Personal Details Section */}
          <div id="template-info" className="section" style={styles.section}>
            <h2 style={styles.sectionH2}>Personal Details</h2>
            {personalDetails.email && personalDetails.email !== "not found" && (
              <p
                id="template-email"
                onClick={clickHandler}
                className="hover:bg-primary/10 cursor-pointer"
                style={styles.personalDetailsP}
              >
                <strong>Email:</strong> {personalDetails.email}
              </p>
            )}
            {personalDetails.phone && personalDetails.phone !== "not found" && (
              <p
                id="template-phone"
                onClick={clickHandler}
                className="hover:bg-primary/10 "
                style={styles.personalDetailsP}
              >
                <strong>Contact No:</strong> {personalDetails.phone}
              </p>
            )}
            {personalDetails.linkedin &&
              personalDetails.linkedin !== "not found" && (
                <p
                  id="template-linkedin"
                  onClick={clickHandler}
                  className="hover:bg-primary/10 "
                  style={styles.personalDetailsP}
                >
                  <strong>LinkedIn:</strong>
                  <a
                    href={`${
                      !personalDetails.linkedin.startsWith("http")
                        ? "https://" + personalDetails.linkedin
                        : personalDetails.linkedin
                    }`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {personalDetails.linkedin}
                  </a>
                </p>
              )}
            {personalDetails.portfolio &&
              personalDetails.portfolio !== "not found" && (
                <p
                  id="template-portfolio"
                  onClick={clickHandler}
                  className="hover:bg-primary/10 "
                  style={styles.personalDetailsP}
                >
                  <strong>Portfolio:</strong>
                  <a
                    href={`${
                      !personalDetails.portfolio.startsWith("http")
                        ? "https://" + personalDetails.portfolio
                        : personalDetails.portfolio
                    }`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {personalDetails.portfolio}
                  </a>
                </p>
              )}
            {personalDetails.location &&
              personalDetails.location !== "not found" && (
                <p
                  id="template-location"
                  onClick={clickHandler}
                  className="hover:bg-primary/10 "
                  style={styles.personalDetailsP}
                >
                  <strong>Location:</strong> {personalDetails.location}
                </p>
              )}
          </div>

          {/* Qualification Section */}
          {Array.isArray(Qualification) && Qualification.length > 0 && (
            <div
              id="template-qualification"
              className="section"
              style={styles.section}
            >
              <h2 style={styles.sectionH2}>Qualification</h2>
              {Qualification.map((qualification, index) => (
                <p
                  id={`template-qualification-${index}`}
                  onClick={clickHandler}
                  className="hover:bg-primary/10 cursor-pointer"
                  style={styles.personalDetailsP}
                  key={index}
                >
                  <strong
                    id={`template-qualification-${index}`}
                    className="hover:bg-primary/10 "
                  >
                    {qualification.degree}
                    {qualification.institute_name &&
                      ` - ${qualification.institute_name}`}
                  </strong>
                  {qualification.percentage && ` (${qualification.percentage})`}
                </p>
              ))}
            </div>
          )}

          {/* Skills Section */}
          {Array.isArray(skills) && skills.length > 0 && (
            <div
              id="template-skills"
              className="  section"
              style={styles.section}
            >
              <h2 style={styles.sectionH2}>Skills</h2>
              <div
                id="template-skills"
                onClick={clickHandler}
                style={styles.skillsList}
              >
                {skills.map((skill, index) => (
                  <span
                    className="hover:bg-primary/10 cursor-pointer"
                    key={index}
                  >
                    {skill}
                    {index !== skills.length - 1 && ", "}
                  </span>
                ))}
              </div>
            </div>
          )}

          {/* Experience Section */}
          {Array.isArray(experience) && experience.length > 0 && (
            <div
              id="template-experience"
              className="section"
              style={styles.section}
            >
              <h2 style={styles.sectionH2}>Experience</h2>
              {experience.map((exp, index) => (
                <div className="job" style={styles.job} key={index}>
                  <h3
                    id={`template-experience-${index}-company`}
                    onClick={clickHandler}
                    className="hover:bg-primary/10 cursor-pointer"
                    style={styles.h3}
                  >
                    {exp.company}
                    {exp.position && ` - ${exp.position}`}
                  </h3>
                  <p
                    id={`template-experience-${index}-duration`}
                    onClick={clickHandler}
                    className="hover:bg-primary/10 cursor-pointer"
                  >
                    {exp.duration}
                  </p>
                  {exp.details.map((detail, i) => (
                    <p
                      id={`template-experience-${index}-details-${i}`}
                      onClick={clickHandler}
                      className="hover:bg-primary/10 cursor-pointer"
                      style={styles.p}
                      key={i}
                    >
                      {conflicts &&
                      difference &&
                      difference["experience"][index].details[i] &&
                      conflicts["experience"] === -1 ? (
                        <TextDiffRenderer
                          diffData={difference["experience"][index].details[i]}
                          accept={`experience-${index}-details-${i}`}
                          actionHandler={actionHandler}
                        />
                      ) : (
                        detail
                      )}
                    </p>
                  ))}
                </div>
              ))}
            </div>
          )}

          {/* Projects Section */}
          {Array.isArray(projects) && projects.length > 0 && (
            <div
              id="template-projects"
              className="section"
              style={styles.section}
            >
              <h2 style={styles.sectionH2}>Projects</h2>
              {projects.map((project, index) => (
                <div className="project" style={styles.project} key={index}>
                  <h3
                    id={`template-projects-${index}-title`}
                    onClick={clickHandler}
                    className="hover:bg-primary/10 cursor-pointer"
                    style={styles.h3}
                  >
                    {project.title} {project.year && `(${project.year})`}
                  </h3>
                  {project.description.map((detail, i) => (
                    <p
                      id={`template-projects-${index}-description-${i}`}
                      onClick={clickHandler}
                      className="hover:bg-primary/10 cursor-pointer"
                      key={i}
                    >
                      {difference &&
                      difference['projects'][index] && 
                      difference["projects"][index]["description"][i] &&
                      conflicts["projects"] === -1 ? (
                        <TextDiffRenderer
                          diffData={
                            difference["projects"][index]["description"][i]
                          }
                          accept={`projects-${index}-description-${i}`}
                          actionHandler={actionHandler}
                        />
                      ) : (
                        detail
                      )}
                    </p>
                  ))}
                </div>
              ))}
            </div>
          )}

          {/* Optional Details Section */}
          {Array.isArray(optionalDetails) && optionalDetails.length > 0 && (
            <div
              id="template-optional"
              className="section"
              style={styles.section}
            >
              <h2 style={styles.sectionH2}>Optionals</h2>
              {optionalDetails.map((detail, index) => (
                <p
                  className="optional-details-description"
                  style={styles.optionalDetailsDescription}
                  key={index}
                >
                  <p
                    id={`template-optional-${index}`}
                    onClick={clickHandler}
                    className="hover:bg-primary/10 cursor-pointer"
                  >
                    {
                    conflicts &&
                    difference &&
                    conflicts["optional"] &&
                    difference["optional"][index] ? (
                      <TextDiffRenderer
                        diffData={difference["optional"][index]}
                        actionHandler={actionHandler}
                        accept={`optional-${index}`}
                      />
                    ) : (
                      detail
                    )}
                  </p>
                </p>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Template2;

import React, { useEffect, useRef, useState } from "react";
import Template_1 from "../templates/template_1";
import loaderJson from "../assets/loader.json";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  lighten,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { MdOutlineClose, MdOutlineExpandMore } from "react-icons/md";
import { io } from "socket.io-client";
import { useNavigate, useParams } from "react-router";
import userData from "../services/userData";
import Cookies from "js-cookie";
import { base2 } from "../http_2";
import Lottie from "react-lottie-player";
import Template2 from "../templates/template_2";
import { useSelector } from "react-redux";
import { PiNotePencilLight } from "react-icons/pi";
import { IoClose } from "react-icons/io5";
import { Loading } from "./InterviewRoom";
import dashboardData from "../services/dashboardData";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaDownload } from "react-icons/fa6";
import { toast } from "react-toastify";
import { FileUploader } from "react-drag-drop-files";
import { setCVDialog } from "../GlobalRedux/Features/GlobalStateSlice";
import ColorPalette from "../components/ColorPalette";
import ResumeTemplate from "../templates/template1";

const getActivity = (service, method) => {
  if (method === 1) {
    if (service === "improve") return "Improved CV based on it's own merit";
    else return "Evaluated CV based on it's own merit";
  } else if (method === 2) {
    if (service === "improve") return "Improved CV based on job role";
    else return "Evaluated CV based on job role";
  } else {
    if (service === "improve")
      return "Improved CV based on job role and desciption";
    else return "Evaluated CV based on job role and desciption";
  }
};

function Card({
  main,
  secondary,
  setOpenForm,
  formName,
  refName,
  handleHover,
}) {
  useEffect(() => {
    console.log("RefName = ", refName);
  }, []);
  return (
    <div
      onClick={() => setOpenForm(formName)}
      className="my-2 bg-primary/10 px-2 py-1 rounded-md flex flex-col justify-between cursor-pointer  hover:-translate-y-[2px] transition duration-300 primary-shadow"
      onMouseEnter={() => handleHover(refName)}
    >
      <div>
        <p className="text-primary-text font-semibold text-xs">{main}</p>
        {/* <p className="text-[10px]">{secondary}</p> */}
      </div>
      <div className="flex justify-end items-end">
        <PiNotePencilLight size={20} className=" text-primary-text" />
      </div>
    </div>
  );
}

const MyCV = () => {
  const fileTypes = ["PDF"];
  const [file, setFile] = useState(null);
  const [jobRole, setJobRole] = useState("");
  const [roleLevel, setRoleLevel] = useState("");
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState(3);
  const [error, setError] = useState("");
  const [progress, setProgress] = useState("Uploading");

  const [savingDataLoader, setSavingDataLoader] = useState(false);
  const [openForm, setOpenForm] = useState("");

  const experienceContainer = useRef(null);
  const projectContainer = useRef(null);
  const optionalContainer = useRef(null);
  const qualificationContainer = useRef(null);
  const summaryContainer = useRef(null);
  const infoContainer = useRef(null);
  const skillsContainer = useRef(null);
  const pdfContainerRef = useRef(null);

  const [mode, setMode] = useState(null);

  const handleClick = async () => {
    try {
      if (mode === "improve") {
        setFileUploadDialog(false);
        upload();
      } else if (mode === "evaluate") {
        console.log("daiisd98s7ads9a ))))))");
        setFileUploadDialog(false);
        upload("evaluate");
      }
    } catch (e) {
    } finally {
      setStep(1);
    }
  };

  const closeForm = () => {
    setOpenForm("");
  };

  const getSessionID = () => {
    return [...Array(16)]
      .map(() => Math.floor(Math.random() * 16).toString(16))
      .join("");
  };

  const interactiveEditing = async (e) => {
    function clickHandler(event) {
      let targetElement = event.target;
      if (targetElement.tagName.toLowerCase() === "a") {
        return null;
      }
      while (targetElement && !targetElement.id.includes("template")) {
        targetElement = targetElement.parentElement;
      }
      if (targetElement) {
        return targetElement.id;
      } else {
        return null;
      }
    }

    // console.log(e);
    const id = clickHandler(e);
    const arr = id?.split("-");
    console.log(arr);

    if (arr && arr.length > 0) {
      if (arr[1] === "skills") {
        setOpenForm("Skills");
      } else if (arr[1] === "experience") {
        setOpenForm("Experience");
        const targetId = "formData-" + arr.slice(1).join("-");
        setTimeout(() => {
          const childElement = experienceContainer.current?.querySelector(
            `#${targetId}`
          );
          if (childElement) {
            childElement.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "nearest",
            });
            setTimeout(() => {
              if (childElement) {
                childElement.focus();
                childElement.setSelectionRange(
                  childElement.value.length,
                  childElement.value.length
                );
              }
            }, 500);
          }
        }, 200);
      } else if (arr[1] === "projects") {
        setOpenForm("Projects");
        const targetId = "formData-" + arr.slice(1).join("-");
        setTimeout(() => {
          const childElement = projectContainer.current?.querySelector(
            `#${targetId}`
          );
          if (childElement) {
            childElement.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "nearest",
            });
            setTimeout(() => {
              if (childElement) {
                childElement.focus();
                childElement.setSelectionRange(
                  childElement.value.length,
                  childElement.value.length
                );
              }
            }, 500);
          }
        }, 200);
      } else if (arr[1] === "optional") {
        setOpenForm("Optionals");
        const targetId = "formData-" + arr.slice(1).join("-");
        setTimeout(() => {
          const childElement = optionalContainer.current?.querySelector(
            `#${targetId}`
          );
          if (childElement) {
            childElement.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "nearest",
            });
            setTimeout(() => {
              if (childElement) {
                childElement.focus();
                childElement.setSelectionRange(
                  childElement.value.length,
                  childElement.value.length
                );
              }
            }, 500);
          }
        }, 200);
      } else if (arr[1] === "qualification") {
        setOpenForm("Qualifications");
        const targetId = "formData-" + arr.slice(1).join("-");
        setTimeout(() => {
          const childElement = qualificationContainer.current?.querySelector(
            `#${targetId}`
          );
          if (childElement) {
            childElement.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "nearest",
            });
            setTimeout(() => {
              if (childElement) {
                childElement.focus();
                childElement.setSelectionRange(
                  childElement.value.length,
                  childElement.value.length
                );
              }
            }, 500);
          }
        }, 200);
      } else if (arr[1] === "cs") {
        setOpenForm("candidate-summary");
        const targetId = "formData-" + arr.slice(1).join("-");
        setTimeout(() => {
          const childElement = summaryContainer.current?.querySelector(
            `#${targetId}`
          );
          if (childElement) {
            childElement.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "nearest",
            });
            setTimeout(() => {
              if (childElement) {
                childElement.focus();
                childElement.setSelectionRange(
                  childElement.value.length,
                  childElement.value.length
                );
              }
            }, 100);
          }
        }, 200);
      } else {
        setOpenForm("Personal info");
        const targetId = "formData-" + arr.slice(1).join("-");
        setTimeout(() => {
          const childElement = infoContainer.current?.querySelector(
            `#${targetId}`
          );
          if (childElement) {
            childElement.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "nearest",
            });
            setTimeout(() => {
              if (childElement) {
                childElement.focus();
                let flag = false;
                if (childElement.type === "email") {
                  childElement.type = "text";
                  flag = true;
                }
                childElement.setSelectionRange(
                  childElement.value.length,
                  childElement.value.length
                );
                if (flag) {
                  childElement.type = "email";
                }
              }
            }, 100);
          }
        }, 200);
      }
    }
  };

  const actionHandler = (action) => {
    try {
      const { payload, section } = action;

      const arr = section.split("-");

      if (arr[0] === "cs") {
        setDifference((prev) => {
          return {
            ...prev,
            summary: "",
          };
        });
        if (payload)
          setFormData((prev) => {
            return {
              ...prev,
              summary: improvedCV["summary"],
            };
          });
      } else if (arr[0] === "experience") {
        console.log(payload, section);
        let index = arr[1];
        let i = arr[3];
        setDifference((prev) => {
          const updated = { ...prev };
          updated["experience"][index].details[i] = null;
          return updated;
        });
        if (payload) {
          setFormData((prev) => {
            const updated = { ...prev };
            updated["experience"][index].details[i] =
              improvedCV["experience"][index].details[i];
            return updated;
          });
        }
      } else if (arr[0] === "projects") {
        let index = arr[1];
        let i = arr[3];
        setDifference((prev) => {
          const updated = { ...prev };
          updated["projects"][index]["description"][i] = null;
          return updated;
        });
        if (payload) {
          setFormData((prev) => {
            const updated = { ...prev };
            updated["projects"][index]["description"][i] =
              improvedCV["projects"][index]["description"][i];
            return updated;
          });
        }
      } else if (arr[0] === "skills") {
      } else if (arr[0] === "optional") {
        let index = arr[1];
        setDifference((prev) => {
          const updated = { ...prev };
          updated["optional"][index] = null;
          return updated;
        });
        if (payload) {
          setFormData((prev) => {
            const updated = { ...prev };
            updated["optional"][index] = improvedCV["optional"][index];
            return updated;
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const [jobDesc, setJobDesc] = useState("");
  const [sessionID, setSessionID] = useState(getSessionID());
  const [nextText, setNextText] = useState("");
  const [socket, setSocket] = useState(null);
  const [trialsRemaining, setTrialsRemaining] = useState(null);
  const [showForm, setShowForm] = useState(0);
  const navigate = useNavigate();
  const textareaRef = useRef(null);
  const dynamic_id = useSelector((state) => state.GlobalState.dynamic_id);
  const [formData, setFormData] = useState({
    summary: "",
    qualifications: [
      { degree: "", institute: "", percentage: "", description: "" },
    ],
    projects: [{ title: "", year: "", description: [""] }],
    experience: [{ company: "", position: "", duration: "", details: [""] }],
    optional: [],
    skills: [],
    name: "",
    location: "",
    email: "",
    phone: "",
    linkedin: "",
    portfolio: "",
  });

  const [conflicts, setConflicts] = useState({});

  const changeQualification = (index, field, val) => {
    setFormData((prev) => {
      const data = { ...prev };
      data.qualifications[index][field] = val;
      return data;
    });
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const changeExperience = (index, field, val) => {
    setFormData((prev) => ({
      ...prev,
      experience: prev.experience.map((exp, i) =>
        i === index ? { ...exp, [field]: val } : exp
      ),
    }));
  };

  const [skillData, setSkillData] = useState("");

  const changeProjects = (index, field, val) => {
    setFormData((prev) => ({
      ...prev,
      projects: prev.projects.map((project, i) =>
        i === index ? { ...project, [field]: val } : project
      ),
    }));
  };

  const editCandidateSummary = async () => {
    try {
      setSavingDataLoader(true);
      const data = {
        dynamic_id,
        candidate_summary: formData.summary,
      };
      const res = await dashboardData.edit_candidate_summary(data);
      if (res) {
        closeForm();
        getCV();
      }
    } catch (error) {
      console.error("Failed to edit candidate summary", error);
    } finally {
      setSavingDataLoader(false);
    }
  };

  const editPersonalDetails = async (e) => {
    e.preventDefault();
    try {
      setSavingDataLoader(true);
      const data = {
        dynamic_id,
        personal_details: {
          name: formData.name,
          location: formData.location,
          email: formData.email,
          portfolio: formData.portfolio,
          contact_no: formData.phone,
          linkedin_url: formData.linkedin,
        },
      };
      const res = await dashboardData.edit_personal_details(data);
      if (res) {
        closeForm("#edit-field-personal-details");
        getCV();
      }
    } catch (err) {
      console.error("Error while editing personal details", err);
    } finally {
      setSavingDataLoader(false);
    }
  };

  const editSkills = async () => {
    try {
      setSavingDataLoader(true);
      const data = {
        dynamic_id,
        skills: formData.skills,
      };
      const res = await dashboardData.edit_skills(data);
      if (res) {
        // Implement the logic to hide the form and refresh the CV
        closeForm("#edit-field-skills");
        getCV();
      }
    } catch (err) {
      console.error("Error while editing skills", err);
    } finally {
      setSavingDataLoader(false);
    }
  };

  const editExperience = async () => {
    try {
      setSavingDataLoader(true);
      const data = {
        dynamic_id,
        experience: formData.experience,
      };
      const res = await dashboardData.edit_experience(data);
      if (res) {
        // Implement the logic to hide the form and refresh the CV
        closeForm("#edit-field-experience");
        getCV();
      }
    } catch (err) {
      console.error("Error while editing experience", err);
    } finally {
      setSavingDataLoader(false);
    }
  };

  const editQualification = async (e) => {
    e.preventDefault();
    try {
      setSavingDataLoader(true);
      const data = {
        dynamic_id,
        qualification: formData.qualifications,
      };
      const res = await dashboardData.edit_qualification(data);
      if (res) {
        // Implement the logic to hide the form and refresh the CV
        closeForm("#edit-field-qualifications");
        getCV();
      }
    } catch (err) {
      console.error("Error while editing qualification", err);
    } finally {
      setSavingDataLoader(false);
    }
  };

  const editProjects = async () => {
    try {
      setSavingDataLoader(true);
      const data = {
        dynamic_id,
        projects: formData.projects,
      };
      const res = await dashboardData.edit_projects(data);
      if (res) {
        // Implement the logic to hide the form and refresh the CV
        closeForm("#edit-field-projects");
        getCV();
      }
    } catch (err) {
      console.error("Error while editing projects", err);
    } finally {
      setSavingDataLoader(false);
    }
  };

  const editOtherDetails = async () => {
    try {
      setSavingDataLoader(true);
      const data = {
        dynamic_id,
        other_details: formData.optional,
      };
      const res = await dashboardData.edit_other_details(data);
      if (res) {
        // Implement the logic to hide the form and refresh the CV
        closeForm("#edit-field-optional-details");
        getCV();
      }
    } catch (err) {
      console.error("Error while editing other details", err);
    } finally {
      setSavingDataLoader(false);
    }
  };

  const submitForm = (form) => {
    console.log(form);
    console.log("Form data:", JSON.parse(JSON.stringify(formData)));
    closeForm(form);
  };

  const addQualificationEntry = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      qualifications: [
        ...prevFormData.qualifications,
        { degree: "", institute: "", percentage: "", description: "" },
      ],
    }));
  };

  const addDetailsPrj = (index) => {
    console.log(`Called to add details for index: ${index}`);
    setFormData((prev) => ({
      ...prev,
      projects: prev.projects.map((project, i) =>
        i === index
          ? { ...project, description: [...project.description, ""] }
          : project
      ),
    }));
  };

  const addProjectEntry = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      projects: [
        ...prevFormData.projects,
        { title: "", year: "", description: [""] },
      ],
    }));
  };

  const addExperienceEntry = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      experience: [
        ...prevFormData.experience,
        { company: "", position: "", duration: "", details: [""] },
      ],
    }));
  };

  const addOptionalEntry = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      optional: [...prevFormData.optional, ""],
    }));
  };

  const addSkillEntry = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      skills: [...prevFormData.skills, ""],
    }));
  };

  const deleteEntry = (collection, index) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [collection]: prevFormData[collection].filter((_, i) => i !== index),
    }));
  };

  let updateId = 0;

  const [uploadDialog, setUploadDialog] = useState(false);

  const { service, method } = useParams();

  const lightenColor = (hex, factor = 0.9) => {
    if (hex.startsWith("#")) {
      hex = hex.slice(1);
    }
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);
    r = Math.min(255, Math.floor(r + (255 - r) * factor));
    g = Math.min(255, Math.floor(g + (255 - g) * factor));
    b = Math.min(255, Math.floor(b + (255 - b) * factor));
    const toHex = (component) => component.toString(16).padStart(2, "0");
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  };
  const getCV = async (did = null) => {
    try {
      const res = await userData.getCV(did || dynamic_id);
      const data = res.data;
      setFormData({
        name: data.name || "",
        email: data.email || "",
        phone: data.contact_no || "",
        linkedin: data.linkedin_url || "",
        portfolio: data.portfolio || "",
        summary: data.candidate_summary || "",
        skills: data.skills || [],
        location: data.location || "",
        projects: data.projects || [],
        experience: data.experience || [],
        qualifications: data.qualification || [],
        courses_and_certifications: data.courses_and_certifications || "",
        awards_and_achievements: data.awards_and_achievements || "",
        optional: data.other_details || [],
      });
      if (data.flag === false) {
        setUploadDialog(true);
      }
      console.log(data);
    } catch (err) {
      console.error("Error while fetching CV", err);
    }
  };

  const arr = [
    {
      main: "Personal info",
      secondary: "Essential contact and personal details.",
      ref: "info",
    },
    {
      main: "Qualifications",
      secondary: "Educational achievements and certifications.",
      ref: "qualification",
    },
    {
      main: "Skills",
      secondary: "Core competencies.",
      ref: "skills",
    },
    {
      main: "Experience",
      secondary: "Professional roles and responsibilities.",
      ref: "experience",
    },
    {
      main: "Projects",
      secondary: "Significant results and contributions.",
      ref: "projects",
    },
    {
      main: "Optionals",
      secondary: "Additional relevant information.",
      ref: "optional",
    },
  ];

  useEffect(() => {
    getTrialsRemaining();
  }, []);

  useEffect(() => {
    getCV();
  }, [dynamic_id]);

  const getTrialsRemaining = async () => {
    const token = Cookies.get("token");
    const data = { token, service };
    try {
      const res = await userData.checkFeedbackStatus(data);
      setTrialsRemaining(res.data?.trials);
      if (res.data && res.data.trials === 0 && !res.data.feedback) {
        setShowForm(1);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [fileUploadDialog, setFileUploadDialog] = useState(false);

  const [improvements, setImprovements] = useState(null);

  const [cvTheme, setCVTheme] = useState({
    fontColor: "#f4bf43",
    backgroundColor: lightenColor("#f4bf43"),
  });
  const [downloading, setDownloading] = useState(false);

  const downloadCV = async () => {
    try {
      setDownloading(true);
      const data = {
        cv: formData,
        theme: cvTheme,
        template_id: id,
      };
      console.log("W#$#", formData.optional);
      const response = await userData.downloadCV(data);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "resume.pdf");
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (err) {
      console.error("Error while downloading CV", err);
    } finally {
      setDownloading(false);
    }
  };

  const sectionArr = [
    {
      label: "candidate_summary",
      value: "Candidate summary",
    },
    {
      label: "Qualification",
      value: "Qualifications",
    },
    {
      label: "skills",
      value: "Skills",
    },
    {
      label: "experience",
      value: "Experience",
    },
    {
      label: "projects",
      value: "Projects",
    },
    {
      label: "other_details",
      value: "Optional details",
    },
  ];
  const [improvedCV, setImprovedCV] = useState(null);
  const [difference, setDifference] = useState(null);
  const [detailedImprovements, setDetailedImprovements] = useState(null);

  const getNumberOfPages = async (pdfFile) => {
    try {
      return 1;
    } catch (error) {
      return 0;
    }
  };

  const [cvUploading, setCVUploading] = useState(false);

  const importResume = async () => {
    if (!file) {
      toast.error("No file uploaded!");
    }
    setCVUploading(true);
    try {
      const form_data = new FormData();
      form_data.append("file", file);
      const res = await userData.importResume(form_data);
      setFormData(res.data);
      setFile(null);
      toast.success("CV parsed successfully!");
    } catch (err) {
      console.log(err);
    } finally {
      setCVUploading(false);
      setUploadDialog(false);
    }
  };

  const upload = async (uploadMode = null) => {
    console.log(formData);
    console.log("Socket connection to : ", sessionID);

    try {
      setProgress("Uploading...");
      setError("");
      const token = Cookies.get("token");
      const getIdres = await userData.getId(token);
      const id = getIdres.data.id;
      const form_data = new FormData();
      form_data.append("id", id);
      form_data.append("job_role", jobRole);
      form_data.append("role_level", roleLevel);
      form_data.append("job_description", jobDesc);
      form_data.append("session_id", sessionID);
      form_data.append("option", method);
      form_data.append("cv", JSON.stringify(formData));
      // console.log("String data = ", JSON.stringify(formData));
      const activity = getActivity(service, method);
      console.log(
        token,
        file,
        id,
        jobRole,
        roleLevel,
        jobDesc,
        sessionID,
        method,
        activity
      );
      setLoading(true);
      if (!uploadMode) {
        form_data.append("activity", "improve");
        const data = { id: id };
        const res_2 = await userData.improve(form_data);
        console.log(res_2);
        setLoading(false);
        if (!res_2.data.ai_improvements) {
          setError("Something went wrong");
          return;
        }
        setDetailedImprovements(res_2.data.detailed_improvements);
        setImprovements(res_2.data.ai_improvements);
        setImprovedCV(res_2.data.cv);
        setDifference(res_2.data.difference);
        setConflicts({
          projects: -1,
          qualifications: -1,
          summary: -1,
          optional: -1,
          skills: -1,
          experience: -1,
        });
        console.log("---------> ", res_2.data.ai_improvements);
        localStorage.setItem(
          "ai_improvements",
          JSON.stringify(res_2.data.ai_improvements)
        );
      } else {
        console.log("here");
        const res_2 = await userData.upload_2(form_data);
        if (
          !res_2.data.score ||
          !res_2.data.gaps ||
          !res_2.data.overall_score ||
          !res_2.data.suggestions
        ) {
          setError("Something went wrong");
          return;
        }
        localStorage.setItem("matrix_score", JSON.stringify(res_2.data.score));
        localStorage.setItem("gaps", JSON.stringify(res_2.data.gaps));
        localStorage.setItem(
          "overall_score",
          JSON.stringify(res_2.data.overall_score)
        );
        localStorage.setItem(
          "suggestions",
          JSON.stringify(res_2.data.suggestions)
        );
        navigate(`/result/evaluate`);
      }
    } catch (e) {
      console.log("Error: %% ", e);
      setError(e.response?.data?.detail || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const fileChangeHandler = (file) => {
    setError("");
    setFile(file);
  };

  const changeText = (nextData) => {
    setNextText(nextData + ".");
    console.log("Changing text from ", progress, " to ", nextData);
    animateTextChange(nextData + ".");
  };

  const animateTextChange = (nextData) => {
    const textarea = textareaRef.current;
    console.log(textarea);
    if (!textarea) return;
    textarea.classList.add("slide-up");

    setTimeout(() => {
      setProgress(nextData);
      textarea.classList.remove("slide-up");
      textarea.classList.add("slide-in");

      setTimeout(() => {
        textarea.classList.remove("slide-in");
      }, 500);
    }, 500);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setMode("improve");
    setFileUploadDialog(true);
  };

  const handleHover = (e) => {
    console.log("Event = ", e);
    let id = null;
    const container = pdfContainerRef.current;
    if (e) {
      id = `template-${e}`;
      let element = document.getElementById(id);
      if (element) {
        const elementOffsetTop = element.offsetTop;
        const containerHeight = container.clientHeight;
        const elementHeight = element.clientHeight;

        // Calculate the target scroll position
        const scrollPosition =
          elementOffsetTop - containerHeight / 2 + elementHeight / 2;

        container.scrollTo({
          top: scrollPosition,
          behavior: "smooth",
        });
      }
    }
  };

  const [savingCV, setSavingCV] = useState(false);

  const saveCV = async (e) => {
    try {
      setSavingCV(true);
      await editCandidateSummary();
      await editPersonalDetails(e);
      await editExperience();
      await editQualification(e);
      await editProjects();
      await editOtherDetails();
      await editSkills();
      console.log("all saved");
      toast.success("Synced data successfully");
    } catch (err) {
      console.log(err);
    } finally {
      setSavingCV(false);
    }
  };

  const [step, setStep] = useState(1);

  const [chooseTemplate, setChooseTemplate] = useState(false);

  useEffect(() => {
    const socket = io(base2, { autoConnect: false });
    socket.connect();
    setSocket(socket);

    socket.on("update", async (data) => {
      console.log(data);
      if (!data) return;
      const message = data.message;

      updateId = Math.max(+message[0], updateId);

      if (!Array.isArray(message)) {
        return;
      }

      for (let i = 1; i < message.length; i++) {
        console.log(message, " <--- ", updateId);
        if (updateId == message[0]) changeText(message[i]);
        await new Promise((resolve) => setTimeout(resolve, 3000));
      }
    });

    socket.emit("join_room", { session_id: sessionID });

    socket.on("joined", (data) => {
      console.log("Joined socket: ", data);
      setSessionID(data.sessionID);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <div>
      <div></div>
      <div className="grid grid-cols-10">
        <div className="p-3 rounded-md bg-white col-span-1">
          <Card
            main={"Candidate summary"}
            setOpenForm={setOpenForm}
            secondary={
              "Overview of key strengths, experience, and career goals."
            }
            formName="candidate-summary"
            handleHover={handleHover}
            refName={"cs"}
          />
          <div className="">
            {arr.map(({ main, secondary, ref }, i) => {
              return (
                <Card
                  main={main}
                  secondary={secondary}
                  key={i}
                  setOpenForm={setOpenForm}
                  formName={main}
                  handleHover={handleHover}
                  refName={ref}
                />
              );
            })}
          </div>
        </div>
        <div className="col-span-6">
          <div className=" my-1 px-3 py-2 rounded-xl flex justify-between items-center">
            {/* <Button color="secondary">Ask appu</Button> */}
            <Button
              color="primary"
              variant="contained"
              disabled={cvUploading}
              onClick={() => setUploadDialog(true)}
            >
              Import
            </Button>
            <Button
              color="error"
              variant="contained"
              onClick={() => setChooseTemplate(true)}
            >
              Templates
            </Button>
            <Button
              color="primary"
              onClick={downloadCV}
              loading={loading}
              disabled={downloading}
              loadingPosition="start"
              startIcon={<FaDownload size={14} />}
              variant="contained"
              // className="flex gap-2 px-3 py-1 rounded-full primary-shadow items-center bg-white "
            >
              <p>Download CV</p>
            </Button>
            <Button
              disabled={savingCV}
              onClick={saveCV}
              color="success"
              variant="contained"
            >
              Save CV
            </Button>
          </div>
          <div className={"primary-shadow border-2 bg-white"}>
            <div
              ref={pdfContainerRef}
              className="h-[100vh] overflow-y-auto overflow-x-scroll lg:overflow-x-visible scale-[0.8]"
            >
              {id === 3 && (
                <Template2
                  data={formData}
                  fontColor={cvTheme.fontColor}
                  clickHandler={interactiveEditing}
                  backgroundColor={cvTheme.backgroundColor}
                  conflicts={conflicts}
                  difference={difference}
                  actionHandler={actionHandler}
                />
              )}
              {id === 4 && (
                <ResumeTemplate
                  data={formData}
                  fontColor={cvTheme.fontColor}
                  clickHandler={interactiveEditing}
                  backgroundColor={cvTheme.backgroundColor}
                  conflicts={conflicts}
                  difference={difference}
                  actionHandler={actionHandler}
                />
              )}
            </div>
          </div>
        </div>
        <div className=" h-full p-2 flex flex-col gap-2 col-span-3">
          <div className="bg-primary/10 border-2 border-primary rounded-xl flex flex-col p-5 gap-4 text-xs">
            <p className="font-bold text-primary-bold">Improve CV</p>
            {error && (
              <p className="text-red-400 font-semibold text-center">{error}</p>
            )}
            {loading && (
              <div className="flex flex-col gap-3 py-4 w-full">
                <div className="flex justify-center w-full">
                  <Lottie
                    loop
                    animationData={loaderJson}
                    play
                    style={{ width: 150, height: 150 }}
                  />
                </div>
                <p
                  ref={textareaRef}
                  className=" text-center my-20 transition-all duration-[500] ease-in-out text-primary-bold font-semibold text-lg"
                >
                  {progress}
                </p>
              </div>
            )}
            {!loading && (
              <button onClick={handleSubmit} className="primary-button">
                Improve
              </button>
            )}
            {!loading && detailedImprovements && (
              <div>
                <p className="font-bold text-primary-bold text-base underline my-0">
                  Improvements:
                </p>
                <ul className="flex flex-col gap-2 mt-4">
                  {sectionArr.map((section, index) => {
                    const { label, value } = section;
                    if (
                      detailedImprovements &&
                      detailedImprovements.filter(
                        ({ location }) => location === label
                      ).length === 0
                    )
                      return null;
                    return (
                      <li key={index}>
                        <Accordion
                          expandIcon={<MdOutlineExpandMore />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          <AccordionSummary>
                            <span className="font-semibold">{value}</span>
                          </AccordionSummary>
                          <AccordionDetails>
                            {
                              <div>
                                {detailedImprovements &&
                                  detailedImprovements
                                    .filter(
                                      ({ location }) => location === label
                                    )
                                    .map(({ text, location }, i) => {
                                      return (
                                        <p key={i}>{`${i + 1}) ` + text}</p>
                                      );
                                    })}
                              </div>
                            }
                          </AccordionDetails>
                        </Accordion>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
          <div className="bg-primary/10 border-2 border-primary rounded-xl flex flex-col p-5 gap-4 text-xs">
            <p className="font-bold text-primary-bold">What more can I do?</p>
            <button
              onClick={(e) => {
                e.preventDefault();
                setMode("evaluate");
                setFileUploadDialog(true);
              }}
              className="secondary-button font-bold"
            >
              Get advice
            </button>
            <Link
              to="/interview-room"
              className="secondary-button font-bold text-center"
            >
              Interview room
            </Link>
          </div>
          <div className="bg-primary/10 border-2 border-primary rounded-xl flex flex-col p-5 gap-4 text-xs">
            <ColorPalette
              onColorChange={(color) => {
                console.log(color);
                setCVTheme(color);
              }}
            />
          </div>
        </div>
      </div>

      <Dialog
        open={openForm === "candidate-summary" ? true : false}
        fullWidth
        maxWidth="lg"
      >
        <div
          className={`pt-12 bg-white flex flex-col transition duration-500 relative
        }`}
        >
          <DialogTitle>
            <p className="text-xl font-bold text-primary-text">
              Candidate summary
            </p>
            <IconButton
              aria-label="close"
              onClick={closeForm}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <IoClose />
            </IconButton>
          </DialogTitle>
          <DialogContent ref={summaryContainer} className=" w-full">
            <TextareaAutosize
              id="formData-cs"
              value={formData.summary}
              onChange={(e) =>
                setFormData((prev) => {
                  return { ...prev, summary: e.target.value };
                })
              }
              className="bg-primary/10 w-full min-h-[250px] p-5 text-lg"
            />
            <div className="flex justify-center my-5">
              {!savingDataLoader && (
                <button
                  className="primary-button mx-auto"
                  onClick={editCandidateSummary}
                >
                  Save
                </button>
              )}
              {savingDataLoader && <Loading></Loading>}
            </div>
          </DialogContent>
        </div>
      </Dialog>

      {/* Personal Info */}

      <Dialog
        open={openForm === "Personal info" ? true : false}
        fullWidth
        maxWidth="lg"
      >
        <div
          className={`pt-12 bg-white flex flex-col transition duration-500 relative
        }`}
        >
          <DialogTitle>
            <p className="text-xl font-bold text-primary-text">Personal info</p>
            <IconButton
              aria-label="close"
              onClick={closeForm}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <IoClose />
            </IconButton>
          </DialogTitle>
          <DialogContent ref={infoContainer} className=" w-full">
            <Box
              component="form"
              className="user-form"
              onSubmit={(e) => e.preventDefault()}
              sx={{ display: "flex", flexDirection: "column", gap: 2 }}
            >
              <Box
                className="scrollable-container py-5"
                sx={{ display: "flex", flexDirection: "column", gap: 2 }}
              >
                <TextField
                  label="Name"
                  id={"formData-name"}
                  value={formData.name}
                  onChange={handleChange}
                />
                <TextField
                  label="Location"
                  id={"formData-location"}
                  value={formData.location}
                  onChange={handleChange}
                />
                <TextField
                  label="Email"
                  id={"formData-email"}
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                <TextField
                  label="Phone"
                  id={"formData-phone"}
                  value={formData.phone}
                  onChange={handleChange}
                />
                <TextField
                  label="LinkedIn"
                  id={"formData-linkedin"}
                  type="url"
                  value={formData.linkedin}
                  onChange={handleChange}
                />
                <TextField
                  label="Portfolio / Website"
                  id={"formData-portfolio"}
                  type="url"
                  value={formData.portfolio}
                  onChange={handleChange}
                />
              </Box>
            </Box>

            <div className="flex justify-center my-5">
              {!savingDataLoader && (
                <button
                  className="primary-button mx-auto"
                  onClick={editPersonalDetails}
                >
                  Save
                </button>
              )}
              {savingDataLoader && <Loading></Loading>}
            </div>
          </DialogContent>
        </div>
      </Dialog>

      {/* Qualifications */}

      <Dialog
        open={openForm === "Qualifications" ? true : false}
        fullWidth
        maxWidth="lg"
      >
        <div
          className={`pt-12 bg-white flex flex-col transition duration-500 relative
        }`}
        >
          <DialogTitle>
            <p className="text-xl font-bold text-primary-text">
              Qualifications
            </p>
            <IconButton
              aria-label="close"
              onClick={closeForm}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <IoClose />
            </IconButton>
          </DialogTitle>
          <DialogContent ref={qualificationContainer} className=" w-full">
            {formData.qualifications &&
              formData.qualifications.map((qual, index) => {
                return (
                  <Box
                    component="form"
                    className="user-form"
                    onSubmit={(e) => e.preventDefault()}
                    sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                    key={index}
                  >
                    <Box
                      className="scrollable-container py-5"
                      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                    >
                      <TextField
                        label="Degree"
                        id={`formData-qualification-${index}`}
                        value={qual.degree}
                        onChange={(e) =>
                          changeQualification(index, "degree", e.target.value)
                        }
                      />
                      <TextField
                        label="Institute Name"
                        id={`formData-${index}-institute_name`}
                        value={qual.institute_name}
                        onChange={(e) =>
                          changeQualification(
                            index,
                            "institute_name",
                            e.target.value
                          )
                        }
                      />
                      <TextField
                        label="Percentage"
                        id="percentage"
                        value={qual.percentage}
                        onChange={(e) =>
                          changeQualification(
                            index,
                            "percentage",
                            e.target.value
                          )
                        }
                      />
                      <TextField
                        label="Description"
                        id="description"
                        value={qual.description}
                        onChange={(e) =>
                          changeQualification(
                            index,
                            "description",
                            e.target.value
                          )
                        }
                      />
                    </Box>

                    <div className="flex justify-end">
                      <button
                        className="secondary-button"
                        onClick={(e) => {
                          e.preventDefault();
                          deleteEntry("qualifications", index);
                        }}
                      >
                        <RiDeleteBin6Line size={25} />
                      </button>
                    </div>
                  </Box>
                );
              })}
            <div className="flex justify-between items-center">
              <button
                className="secondary-button"
                onClick={addQualificationEntry}
              >
                Add more
              </button>
            </div>

            <div className="flex justify-center my-5">
              {!savingDataLoader && (
                <button
                  className="primary-button mx-auto"
                  onClick={editQualification}
                >
                  Save
                </button>
              )}
              {savingDataLoader && <Loading></Loading>}
            </div>
          </DialogContent>
        </div>
      </Dialog>

      {/* Skills */}

      <Dialog
        open={openForm === "Skills" ? true : false}
        fullWidth
        maxWidth="lg"
      >
        <div
          className={`pt-12 bg-white flex flex-col transition duration-500 relative
        }`}
        >
          <DialogTitle>
            <p className="text-xl font-bold text-primary-text">Skills</p>
            <IconButton
              aria-label="close"
              onClick={closeForm}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <IoClose />
            </IconButton>
          </DialogTitle>

          <DialogContent ref={skillsContainer} className=" w-full">
            <div className="flex flex-wrap gap-1">
              {formData.skills &&
                formData.skills.map((skill, i) => {
                  return (
                    <div
                      key={i}
                      className="flex justify-center gap-x-1 gap-y-2 items-center bg-primary/10 px-8 py-1 rounded-full"
                    >
                      <p>{skill}</p>
                      <div
                        className="rounded-full p-1 hover:bg-gray-300 cursor-pointer"
                        onClick={() =>
                          setFormData((prev) => {
                            return {
                              ...prev,
                              skills: prev.skills.filter((sk) => sk !== skill),
                            };
                          })
                        }
                      >
                        <MdOutlineClose />
                      </div>
                    </div>
                  );
                })}
            </div>

            <form className="flex items-center gap-2 my-5 justify-center">
              <TextField
                value={skillData}
                onChange={(e) => setSkillData(e.target.value)}
                onSubmit={(e) => e.preventDefault()}
              />
              <input
                type="submit"
                className="secondary-button"
                value="Add"
                onClick={(e) => {
                  e.preventDefault();
                  setFormData((prev) => ({
                    ...prev,
                    skills: [...prev.skills, skillData],
                  }));
                  setSkillData("");
                }}
              />
            </form>

            <div className="flex justify-center my-5">
              {!savingDataLoader && (
                <button className="primary-button mx-auto" onClick={editSkills}>
                  Save
                </button>
              )}
              {savingDataLoader && <Loading></Loading>}
            </div>
          </DialogContent>
        </div>
      </Dialog>

      {/* Experience */}

      <Dialog
        open={openForm === "Experience" ? true : false}
        fullWidth
        maxWidth="lg"
      >
        <div
          className={`pt-12 bg-white flex flex-col transition duration-500 relative
        }`}
        >
          <DialogTitle>
            <p className="text-xl font-bold text-primary-text">Experience</p>
            <IconButton
              aria-label="close"
              onClick={closeForm}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <IoClose />
            </IconButton>
          </DialogTitle>
          <DialogContent ref={experienceContainer} className=" w-full">
            {formData.experience &&
              formData.experience.map((exp, index) => {
                return (
                  <Box
                    component="form"
                    className="user-form"
                    onSubmit={(e) => e.preventDefault()}
                    sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                    key={index}
                  >
                    <Box
                      className="scrollable-container py-5"
                      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                    >
                      <TextField
                        label="Company"
                        id={`formData-experience-${index}-company`}
                        value={exp.company}
                        onChange={(e) =>
                          changeExperience(index, "company", e.target.value)
                        }
                      />
                      <TextField
                        label="Position"
                        id={`formData-experience-${index}-position`}
                        value={exp.position}
                        onChange={(e) =>
                          changeExperience(index, "position", e.target.value)
                        }
                      />
                      <TextField
                        label="Duration"
                        id={`formData-experience-${index}-duration`}
                        value={exp.duration}
                        onChange={(e) =>
                          changeExperience(index, "duration", e.target.value)
                        }
                      />

                      <div className="flex flex-col gap-2">
                        <strong className="text-primary-bold">Details:</strong>
                        {exp.details &&
                          exp.details.map((d, i) => {
                            return (
                              <div className="w-full relative" key={i}>
                                <TextareaAutosize
                                  id={`formData-experience-${index}-details-${i}`}
                                  value={d}
                                  onChange={(e) =>
                                    setFormData((prev) => {
                                      const upd = { ...prev };
                                      upd.experience[index].details[i] =
                                        e.target.value;
                                      return upd;
                                    })
                                  }
                                  className="bg-primary/10 p-3 rounded-md pr-10 w-full"
                                />
                                <div
                                  onClick={() =>
                                    setFormData((prev) => ({
                                      ...prev,
                                      experience: prev.experience.map(
                                        (exp, expIndex) =>
                                          expIndex === index
                                            ? {
                                                ...exp,
                                                details: exp.details.filter(
                                                  (_, ind) => ind !== i
                                                ),
                                              }
                                            : exp
                                      ),
                                    }))
                                  }
                                  className="rounded-full p-2 absolute top-2 right-5 hover:bg-primary/10 transition duration-300 cursor-pointer"
                                >
                                  <RiDeleteBin6Line className="" size={20} />
                                </div>
                              </div>
                            );
                          })}
                        <button
                          className="secondary-button"
                          onClick={(e) => {
                            e.preventDefault();
                            // addDetailsExp(index);
                            setFormData((prev) => ({
                              ...prev,
                              experience: prev.experience.map((exp, i) =>
                                i === index
                                  ? { ...exp, details: [...exp.details, ""] }
                                  : exp
                              ),
                            }));
                          }}
                        >
                          Add more details
                        </button>
                      </div>
                    </Box>

                    <div className="flex justify-end">
                      <button
                        className="secondary-button"
                        onClick={(e) => {
                          e.preventDefault();
                          deleteEntry("experience", index);
                        }}
                      >
                        <RiDeleteBin6Line size={25} />
                      </button>
                    </div>
                  </Box>
                );
              })}
            <div className="flex justify-between items-center">
              <button className="secondary-button" onClick={addExperienceEntry}>
                Add more
              </button>
            </div>

            <div className="flex justify-center my-5">
              {!savingDataLoader && (
                <button
                  className="primary-button mx-auto"
                  onClick={editExperience}
                >
                  Save
                </button>
              )}
              {savingDataLoader && <Loading></Loading>}
            </div>
          </DialogContent>
        </div>
      </Dialog>

      {/* Projects */}

      <Dialog
        open={openForm === "Projects" ? true : false}
        fullWidth
        maxWidth="lg"
      >
        <div
          className={`pt-12 bg-white flex flex-col transition duration-500 relative
        }`}
        >
          <DialogTitle>
            <p className="text-xl font-bold text-primary-text">Projects</p>
            <IconButton
              aria-label="close"
              onClick={closeForm}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <IoClose />
            </IconButton>
          </DialogTitle>
          <DialogContent ref={projectContainer} className=" w-full">
            {formData.projects &&
              formData.projects.map((prj, index) => {
                return (
                  <Box
                    component="form"
                    className="user-form"
                    onSubmit={(e) => e.preventDefault()}
                    sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                    key={index}
                  >
                    <Box
                      className="scrollable-container py-5"
                      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                    >
                      <TextField
                        label="Title"
                        id={`formData-projects-${index}-title`}
                        value={prj.title}
                        onChange={(e) =>
                          changeProjects(index, "title", e.target.value)
                        }
                      />
                      <TextField
                        label="Year"
                        id={`formData-projects-${index}-year`}
                        value={prj.year}
                        onChange={(e) =>
                          changeProjects(index, "year", e.target.value)
                        }
                      />
                      <div className="flex flex-col gap-2">
                        <strong className="text-primary-bold">
                          Description:
                        </strong>
                        {prj.description &&
                          prj.description.map((d, i) => {
                            return (
                              <div className="w-full relative" key={i}>
                                <TextareaAutosize
                                  value={d}
                                  id={`formData-projects-${index}-description-${i}`}
                                  onChange={(e) =>
                                    setFormData((prev) => {
                                      const upd = { ...prev };
                                      upd.projects[index].description[i] =
                                        e.target.value;
                                      return upd;
                                    })
                                  }
                                  className="bg-primary/10 p-3 rounded-md pr-10 w-full"
                                />
                                <div
                                  onClick={() =>
                                    setFormData((prev) => ({
                                      ...prev,
                                      projects: prev.projects.map(
                                        (project, projIndex) =>
                                          projIndex === index
                                            ? {
                                                ...project,
                                                description:
                                                  project.description.filter(
                                                    (_, ind) => ind !== i
                                                  ),
                                              }
                                            : project
                                      ),
                                    }))
                                  }
                                  className="rounded-full p-2 absolute top-2 right-5 hover:bg-primary/10 transition duration-300 cursor-pointer"
                                >
                                  <RiDeleteBin6Line className="" size={20} />
                                </div>
                              </div>
                            );
                          })}
                        <button
                          className="secondary-button"
                          onClick={(e) => {
                            e.preventDefault();
                            addDetailsPrj(index);
                          }}
                        >
                          Add more details
                        </button>
                      </div>
                    </Box>

                    <div className="flex justify-end">
                      <button
                        className="secondary-button"
                        onClick={(e) => {
                          e.preventDefault();
                          deleteEntry("projects", index);
                        }}
                      >
                        <RiDeleteBin6Line size={25} />
                      </button>
                    </div>
                  </Box>
                );
              })}
            <div className="flex justify-between items-center">
              <button className="secondary-button" onClick={addProjectEntry}>
                Add more
              </button>
            </div>

            <div className="flex justify-center my-5">
              {!savingDataLoader && (
                <button
                  className="primary-button mx-auto"
                  onClick={editProjects}
                >
                  Save
                </button>
              )}
              {savingDataLoader && <Loading></Loading>}
            </div>
          </DialogContent>
        </div>
      </Dialog>

      {/* Optionals */}

      <Dialog
        open={openForm === "Optionals" ? true : false}
        fullWidth
        maxWidth="lg"
      >
        <div
          className={`pt-12 bg-white flex flex-col transition duration-500 relative
        }`}
        >
          <DialogTitle>
            <p className="text-xl font-bold text-primary-text">
              Optional Details
            </p>
            <IconButton
              aria-label="close"
              onClick={closeForm}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <IoClose />
            </IconButton>
          </DialogTitle>
          <DialogContent ref={optionalContainer} className=" w-full">
            <div className="flex flex-col gap-1">
              {formData.optional &&
                formData.optional.map((op, index) => {
                  return (
                    <div
                      className="flex gap-2 items-center my-2 w-full rounded-md"
                      key={index}
                    >
                      <TextField
                        value={op}
                        id={`formData-optional-${index}`}
                        onChange={(e) => {
                          setFormData((prev) => {
                            const upd = prev.optional;
                            upd[index] = e.target.value;
                            return {
                              ...prev,
                              optional: upd,
                            };
                          });
                        }}
                        className="w-full bg-primary/10"
                      />
                      <div
                        className="p-2 rounded-full hover:bg-gray-200 cursor-pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          setFormData((prev) => ({
                            ...prev,
                            optional: prev.optional.filter(
                              (_, i) => i !== index
                            ),
                          }));
                        }}
                      >
                        <RiDeleteBin6Line
                          size={25}
                          className=" top-4 right-2"
                        />
                      </div>
                    </div>
                  );
                })}
            </div>

            <button
              className="secondary-button"
              onClick={(e) => {
                e.preventDefault();
                setFormData((prev) => {
                  return {
                    ...prev,
                    optional: [...prev.optional, ""],
                  };
                });
              }}
            >
              Add more
            </button>

            <div className="flex justify-center my-5">
              {!savingDataLoader && (
                <button
                  className="primary-button mx-auto"
                  onClick={editOtherDetails}
                >
                  Save
                </button>
              )}
              {savingDataLoader && <Loading></Loading>}
            </div>
          </DialogContent>
        </div>
      </Dialog>

      <Dialog open={uploadDialog} onClose={() => setUploadDialog(false)}>
        <DialogTitle>Upload an existing CV</DialogTitle>
        <DialogContent>
          <div className="mb-5">
            <FileUploader
              handleChange={fileChangeHandler}
              onSizeError={() => setError("File size should not exceed 10MB! ")}
              name="file"
              types={fileTypes}
              maxSize={10}
              className={"scale-50"}
            />
            <p>{file ? `File name: ${file?.name}` : "no files uploaded yet"}</p>
          </div>
          {!cvUploading && (
            <Button
              onClick={importResume}
              className="w-full"
              variant="contained"
            >
              Upload
            </Button>
          )}
          {cvUploading && <Loading />}
          {!cvUploading && (
            <div className="mt-2">
              <p className="my-5 text-center">OR</p>
              <Button
                className="w-full"
                color="success"
                variant="contained"
                onClick={() => {
                  setUploadDialog(false);
                  toast.success("Click on the items on left to edit");
                }}
              >
                Fill manually
              </Button>
            </div>
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        open={fileUploadDialog}
        onClose={() => {
          setFileUploadDialog(false);
          setStep(1);
        }}
      >
        <DialogContent>
          <div className="w-[100%] overflow-hidden">
            <div
              className={`w-[200%] min-h-[30vh]  grid grid-cols-2 ${
                step === 2 && "-translate-x-[50%]"
              } transition duration-500`}
            >
              <div className="h-full bg-primary/10 px-5 py-5 rounded-lg">
                <InputLabel>Do you have the job role (optional)? </InputLabel>
                <TextField
                  value={jobRole}
                  onChange={(e) => setJobRole(e.target.value)}
                  className="w-full block mt-5"
                  required
                />
                <div className="flex justify-center mt-4">
                  <Button
                    variant="contained"
                    onClick={() => setStep(2)}
                    className="w-full"
                  >
                    Next
                  </Button>
                </div>
              </div>
              <div className="h-full bg-primary/10 px-5 py-5 rounded-lg">
                <InputLabel>Do you have job description (optional)?</InputLabel>
                <TextField
                  value={jobDesc}
                  onChange={(e) => setJobDesc(e.target.value)}
                  className="w-full block mt-5"
                />
                <div className="flex justify-center mt-4">
                  <Button
                    variant="contained"
                    onClick={(e) => {
                      e.preventDefault();
                      handleClick();
                    }}
                    className="w-full"
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={Boolean(chooseTemplate)}
        onClose={() => setChooseTemplate(false)}
      >
        <DialogTitle>Choose a template</DialogTitle>
        <DialogContent>
          <div className="flex justify-center gap-8">
            <Button
              onClick={() => {
                setId(3);
                setChooseTemplate(false);
              }}
            >
              <img src="/template_2.png" />
            </Button>
            <Button
              onClick={() => {
                setId(4);
                setChooseTemplate(false);
              }}
            >
              <img src="/NewCV_2.png" />
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default MyCV;

import React, { useEffect, useState } from "react";
import TextDiffRenderer from "../components/TextDiffRendered";

const ResumeTemplate = ({
  data,
  fontColor = "#0073e6", // Default color if not provided
  backgroundColor = "white", // Default background color if not provided
  clickHandler,
  actionHandler,
  conflicts,
  difference,
}) => {
  const [personalDetails, setPersonalDetails] = useState({});
  const [qualifications, setQualifications] = useState([]);
  const [skills, setSkills] = useState([]);
  const [experiences, setExperiences] = useState([]);
  const [projects, setProjects] = useState([]);
  const [optionalDetails, setOptionalDetails] = useState("");

  useEffect(() => {
    console.log("CV data = ", data);
    if (data) {
      setPersonalDetails({
        name: data.name,
        location: data.location,
        email: data.email,
        phone: data.phone,
        linkedin: data.linkedin,
        portfolio: data.portfolio,
      });
      setQualifications(data?.qualifications);
      setSkills(data?.skills);
      setOptionalDetails(data?.optional);
      setExperiences(data?.experience);
      setProjects(data?.projects);
      console.log(data.candidate_summary);
    }
  }, [data]);

  const styles = {
    container: {
      fontFamily: "'Arial', sans-serif",
      maxWidth: "900px",
      margin: "20px auto",
      padding: "20px",
      backgroundColor: backgroundColor,
      borderRadius: "10px",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: `2px solid ${fontColor}`,
      paddingBottom: "20px",
      marginBottom: "20px",
    },
    personalInfo: {
      flex: 1,
    },
    h1: {
      fontSize: "30px",
      color: "#333",
      marginBottom: "5px",
    },
    p: {
      margin: "0",
      fontSize: "14px",
      color: "#555",
    },
    section: {
      marginBottom: "30px",
    },
    sectionHeader: {
      fontSize: "24px",
      color: fontColor,
      borderBottom: `2px solid ${fontColor}`,
      paddingBottom: "10px",
      marginBottom: "15px",
    },
    list: {
      fontSize: "14px",
      color: "#555",
      lineHeight: 1.6,
      marginBottom: "10px",
    },
    skillsList: {
      display: "flex",
      flexWrap: "wrap",
      listStyleType: "none",
      padding: 0,
    },
    skillItem: {
      backgroundColor: fontColor,
      color: "white",
      padding: "5px 10px",
      borderRadius: "5px",
      margin: "5px",
      fontSize: "13px",
    },
    link: {
      color: fontColor,
    },
  };

  if (!data) return null;
  return (
    <div style={styles.container}>
      {/* Personal Details Section */}
      {(personalDetails.name ||
        personalDetails.email ||
        personalDetails.phone ||
        personalDetails.linkedin ||
        personalDetails.portfolio ||
        personalDetails.location) && (
        <div id="template-info" style={styles.header}>
          <div style={styles.personalInfo}>
            <h1 style={styles.h1}>
              {personalDetails.name || "Name not provided"}
            </h1>
            {personalDetails.email && (
              <p style={styles.p}>
                <strong>Email:</strong> {personalDetails.email}
              </p>
            )}
            {personalDetails.phone && (
              <p style={styles.p}>
                <strong>Phone:</strong> {personalDetails.phone}
              </p>
            )}
            {personalDetails.linkedin && (
              <p style={styles.p}>
                <strong>LinkedIn:</strong>
                <a
                  href={personalDetails.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={styles.link}
                >
                  {personalDetails.linkedin}
                </a>
              </p>
            )}
            {personalDetails.portfolio && (
              <p style={styles.p}>
                <strong>Portfolio:</strong>
                <a
                  href={personalDetails.portfolio}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={styles.link}
                >
                  {personalDetails.portfolio}
                </a>
              </p>
            )}
            {personalDetails.location && (
              <p style={styles.p}>
                <strong>Location:</strong> {personalDetails.location}
              </p>
            )}
          </div>
        </div>
      )}

      {/* Qualification Section */}
      {qualifications.length > 0 && (
        <div
          id="template-qualification"
          className="section"
          style={styles.section}
        >
          <h2 style={styles.sectionHeader}>Qualification</h2>
          {qualifications.map((qualification, index) => (
            <p style={styles.list} key={index}>
              {qualification.degree} - {qualification.institute}
              {qualification.percentage && ` (${qualification.percentage}%)`}
            </p>
          ))}
        </div>
      )}

      {/* Skills Section */}
      {skills.length > 0 && (
        <div id="template-skills" className="section" style={styles.section}>
          <h2 style={styles.sectionHeader}>Skills</h2>
          <ul style={styles.skillsList}>
            {skills.map((skill, index) => (
              <li style={styles.skillItem} key={index}>
                {skill}
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Experience Section */}
      {experiences.length > 0 && (
        <div
          id="template-experience"
          className="section"
          style={styles.section}
        >
          <h2 style={styles.sectionHeader}>Experience</h2>
          {experiences.map((experience, index) => (
            <div key={index}>
              <h3>
                {experience.position} - {experience.company}
              </h3>
              <p style={styles.list}>{experience.duration}</p>
              {experience.details.map((detail, i) => (
                <p
                  id={`template-experience-${index}-details-${i}`}
                  onClick={clickHandler}
                  className="hover:bg-primary/10 cursor-pointer"
                  style={styles.list}
                  key={i}
                >
                  {conflicts &&
                  difference &&
                  difference["experience"][index].details[i] &&
                  conflicts["experience"] === -1 ? (
                    <TextDiffRenderer
                      diffData={difference["experience"][index].details[i]}
                      accept={`experience-${index}-details-${i}`}
                      actionHandler={actionHandler}
                    />
                  ) : (
                    detail
                  )}
                </p>
              ))}{" "}
            </div>
          ))}
        </div>
      )}

      {/* Projects Section */}
      {projects.length > 0 && (
        <div className="section" id="template-projects" style={styles.section}>
          <h2 style={styles.sectionHeader}>Projects</h2>
          {projects.map((project, index) => (
            <div key={index}>
              <h3>
                {project.title} {project.year && `(${project.year})`}
              </h3>
              {project.description.map((detail, i) => (
                <p
                  id={`template-projects-${index}-description-${i}`}
                  onClick={clickHandler}
                  style={styles.list}
                  className="hover:bg-primary/10 cursor-pointer"
                  key={i}
                >
                  {difference &&
                  difference["projects"][index] &&
                  difference["projects"][index]["description"][i] &&
                  conflicts["projects"] === -1 ? (
                    <TextDiffRenderer
                      diffData={difference["projects"][index]["description"][i]}
                      accept={`projects-${index}-description-${i}`}
                      actionHandler={actionHandler}
                    />
                  ) : (
                    detail
                  )}
                </p>
              ))}{" "}
            </div>
          ))}
        </div>
      )}

      {/* Optional Details Section */}
      {optionalDetails && (
        <div id="template-optional" className="section" style={styles.section}>
          <h2 style={styles.sectionHeader}>Optional Details</h2>
          <p style={styles.list}>{optionalDetails}</p>
        </div>
      )}
    </div>
  );
};

export default ResumeTemplate;

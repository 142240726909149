import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { HiOutlineUserCircle } from "react-icons/hi2";
import { GoHome } from "react-icons/go";
import { GrCopy } from "react-icons/gr";
import { HiMiniArrowTrendingUp } from "react-icons/hi2";
import { FaCheckDouble, FaCross } from "react-icons/fa6";
import { IoPeople } from "react-icons/io5";
import { VscFeedback } from "react-icons/vsc";
import { FiDownload } from "react-icons/fi";
import { BsArrowRightCircle } from "react-icons/bs";
import { RiArrowDropDownLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { RiCloseLargeLine } from "react-icons/ri";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  setCVDialog,
  setSideFlag,
} from "../GlobalRedux/Features/GlobalStateSlice";
import userData from "../services/userData";

function Dropdown({ label }) {
  const navigate = useNavigate();

  const data = [
    "Based on its own merit",
    "Based on a job role",
    "Based on a job role and a description",
  ];

  const service = label.includes("Improve") ? "improve" : "evaluate";

  return (
    <Accordion
      sx={{
        backgroundColor: "transparent",
        boxShadow: "none",
        padding: 0,
        margin: 0,
        color: "white",
        fontWeight: "bold",
      }}
    >
      <AccordionSummary
        expandIcon={<RiArrowDropDownLine size={25} color="white" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ backgroundColor: "transparent", padding: 0 }}
      >
        <Typography
          sx={{
            textAlign: "left",
            fontWeight: "bold",
            padding: 0,
            boxShadow: null,
          }}
          className="whitespace-nowrap"
        >
          {label}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ backgroundColor: "transparent", padding: 0 }}>
        <div className="bg-white text-primary-text p-2 rounded-lg">
          {data.map((item, index) => (
            <Typography
              onClick={() => navigate(`/file-upload/${service}/${index + 1}`)}
              key={index}
              sx={{ textAlign: "left", marginBlock: "10px" }}
              className=" bg-primary/10 px-3 py-1 rounded-md cursor-pointer hover:bg-primary/20"
            >
              {item}
            </Typography> // Modified line
          ))}
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

function DropdownActivities() {
  const [activity, setActivity] = useState(null);

  const getActivity = async () => {
    try {
      const token = Cookies.get("token");
      const res = await userData.getActivity(token);
      setActivity(res.data?.data || []);
      console.log(res.data?.data);
    } catch (err) {
      console.error("Error while fetching activities", err);
    }
  };

  useEffect(() => {
    getActivity();
  }, []);

  const [flag, setFlag] = useState(false);

  function formatDate(isoString) {
    const date = new Date(isoString);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12;
    const formattedHours = String(hours).padStart(2, "0");

    const formattedDate = `${day}/${month}/${year}, ${formattedHours}:${minutes} ${ampm}`;

    return formattedDate;
  }

  return (
    <div>
      <p className="cursor-pointer" onClick={() => setFlag(true)}>
        Your activities
      </p>
      <Dialog open={flag} onClose={() => setFlag(false)}>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Activity</TableCell>
                  <TableCell>Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {activity &&
                  activity.map((obj, index) => (
                    <TableRow key={index}>
                      <TableCell>{obj.activity}</TableCell>
                      <TableCell>{formatDate(obj.createdat)}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </div>
  );
}

function Sidenav() {
  const navigate = useNavigate();

  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();

  const dynamic_id = useSelector((state) => state.GlobalState.dynamic_id);
  const location = useLocation();

  const sideFlag = useSelector((state) => state.GlobalState.sideFlag);

  const ref = useRef();

  if (
    location.pathname === "/change-password" ||
    location.pathname === "/admin-portal" ||
    location.pathname === "/profile"
  ) {
    return null;
  }
  return (
    <div className="">

      <div
        ref={ref}
        className={`${
          !sideFlag && "-translate-x-[110%]"
        } transition-all duration-500 fixed z-[10000] nmd:hidden w-[200px] top-2 left-1 mx-auto`}
      >
        <div
          className={` vertical-gradient pt-14 pb-7 px-4 rounded-3xl transition-all duration-[1000] flex-col justify-between border-2 h-[98vh] border-black relative`}
        >
          <div className="absolute top-6 right-6">
            {" "}
            <RiCloseLargeLine
              size={25}
              onClick={() => dispatch(setSideFlag(false))}
            />
          </div>
          <div className="overflow-y-auto overflow-x-hidden">
            <p className="my-5 font-bold text-2xl">PARINAAM</p>
            <div className="flex flex-col gap-4 items-start font-bold text-base mt-12">
              {/* <NavLink to={`/user-home/${dynamic_id}`}>Dashboard</NavLink> */}
              <Dropdown label={"Improve CV"} />
              <Dropdown label={"Evaluate CV"} />
              <NavLink to="/interview-room">Interview room</NavLink>
              <DropdownActivities />
            </div>
          </div>

          <div className="flex flex-col w-full gap-3 mt-20">
            <NavLink
              to="/feedback"
              className="white-button w-full hover:bg-gray-300 transition duration-300"
            >
              Feedback
            </NavLink>
            <button
              className="white-button w-full hover:bg-gray-300 transition duration-300"
              onClick={(e) => {
                e.preventDefault();
                dispatch(setCVDialog(true));
              }}
            >
              Download CV
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

/**
 * 
 *  
 *  
 */

export default Sidenav;

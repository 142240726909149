import React, { useState, useEffect } from "react";
import { Box, Button, IconButton, Input, Typography } from "@mui/material";
import { FiEdit } from "react-icons/fi";

const ColorPalette = ({ onColorChange }) => {
  const [colors] = useState([
    "#f4bf43",
    "#808080",
    "#fa0202",
    "#001aff",
    "#0077ff",
    "#1ab878",
  ]);
  const [customColor, setCustomColor] = useState("#f4bf43");
  const [active, setActive] = useState(0);

  useEffect(() => {
    applyColor(0);
  }, []);

  const applyColor = (index) => {
    setActive(index);
    setCustomColor(colors[index]);
    onColorChange({
      fontColor: colors[index],
      backgroundColor: lightenColor(colors[index]),
    });
  };

  const applyCustomColor = (event) => {
    const color = event.target.value;
    setCustomColor(color);
    setActive(colors.length);
    onColorChange({ fontColor: color, backgroundColor: lightenColor(color) });
  };

  const lightenColor = (hex, factor = 0.9) => {
    if (hex.startsWith("#")) {
      hex = hex.slice(1);
    }
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);
    r = Math.min(255, Math.floor(r + (255 - r) * factor));
    g = Math.min(255, Math.floor(g + (255 - g) * factor));
    b = Math.min(255, Math.floor(b + (255 - b) * factor));
    const toHex = (component) => component.toString(16).padStart(2, "0");
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  };

  return (
    <div className="flex flex-col gap-6 px-5">
      <p className="text-lg font-bold text-primary-bold">Theme color</p>

      <div className="flex gap-5 flex-wrap">
        {colors.map((color, index) => {
          return (
            <div
              onClick={() => applyColor(index)}
              style={{ backgroundColor: color }}
              key={index}
              className={`aspect-square w-8 rounded-full cursor-pointer hover:shadow-xl ${
                active === index && "border-[3px] border-black"
              }`}
            />
          );
        })}
      </div>

      <div className="flex gap-1 items-center">
        <label htmlFor="color-input font-semibold">
          <span className="font-bold text-primary-bold">Selected theme:</span>
          <span className="text-secondary-text block">
            {"(or select custom)"}
          </span>
        </label>
        <input
          id="color-input"
          type="color"
          value={customColor}
          onChange={applyCustomColor}
          className="aspect-square h-12 cursor-pointer"
        />
      </div>
    </div>
  );
};

export default ColorPalette;

import { Button } from "@mui/material";
import React from "react";

// Styles for different change types
const styles = {
  insert: {
    backgroundColor: "lightgreen",
    color: "green",
  },
  replaceOriginal: {
    textDecoration: "line-through",
    color: "red",
  },
  replaceRewritten: {
    backgroundColor: "lightgreen",
    color: "green",
  },
  equal: {
    color: "black",
  },
  delete: {
    textDecoration: "line-through",
    color: "red",
  },
};

const TextDiffRenderer = ({ diffData , accept , actionHandler }) => {

  const clickHandler = (payload) => {
    actionHandler({
      section: accept,
      payload,
    })
  }

  return (
    <div
      className={`${
        diffData &&
        diffData.filter(({ type }) => type != "equal").length > 0 &&
        "border-2 border-primary-bold"
      }`}
    >
      {diffData.map((diff, index) => {
        if (diff.type === "insert") {
          return (
            <span key={index} style={styles.insert}>
              {diff.text}
            </span>
          );
        }

        if (diff.type === "replace") {
          return (
            <span key={index}>
              <span style={styles.replaceOriginal}>{diff.original}</span>
              <span style={styles.replaceRewritten}>{diff.rewritten}</span>
            </span>
          );
        }

        if (diff.type === "delete") {
          return (
            <span key={index} style={styles.delete}>
              {diff.text}
            </span>
          );
        }

        if (diff.type === "equal") {
          return (
            <span
              key={index}
              style={
                diffData &&
                diffData.filter(({ type }) => type != "equal").length > 0
                  ? styles.replaceRewritten
                  : styles.equal
              }
            >
              {diff.text}
            </span>
          );
        }

        return null;
      })}
      {diffData &&
        diffData.filter(({ type }) => type != "equal").length > 0 && (
          <div className="bg-primary/10 py-2 flex justify-between px-4">
            <Button variant="contained" color="success" onClick={(e) => {e.stopPropagation(); clickHandler(true)}}>
              Accept
            </Button>
            <Button variant="outlined" color="error" onClick={(e) => {e.stopPropagation(); clickHandler(false)}}>
              Reject
            </Button>
          </div>
        )}
    </div>
  );
};

export default TextDiffRenderer;

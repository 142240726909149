import { TextField } from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import userData from "../services/userData";
import { MuiOtpInput } from "mui-one-time-password-input";
import {
  Dialog,
  DialogContent,
  Card,
  CardContent,
  Button,
  Typography,
} from "@mui/material";

import Cookies from "js-cookie";

// Rest of the code...

export const Divider = () => {
  return <div className="w-full h-[1px]  bg-gray-300 "></div>;
};

function Signup() {
  const [hide, setHide] = useState(true);
  const [email, setEmail] = useState("");
  const [toggle, setToggle] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [password, setPassword] = useState("");
  const [verificationStatus, setVerificationStatus] = useState("");
  const [OTPModal, setOTPModal] = useState(false);
  const [otp, setOtp] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [error2, setError2] = useState("");
  const [error3, setError3] = useState("");
  const [registration, setRegistration] = useState(false);
  const [loginButton, setLoginButton] = useState(false);
  const [timer, setTimer] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const rules = {
    required: (value) => !!value || "Required",
    email: (value) => {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "Invalid email";
    },
    min: (v) => v.length >= 8 || "Min 8 characters",
    uppercase: (value) =>
      /[A-Z]/.test(value) ||
      "Password must contain at least one uppercase letter",
    lowercase: (value) =>
      /[a-z]/.test(value) ||
      "Password must contain at least one lowercase letter",
    number: (value) =>
      /[0-9]/.test(value) || "Password must contain at least one number",
    specialChar: (value) =>
      /[!@#$%^&*(),.?":{}|<>]/.test(value) ||
      "Password must contain at least one special character",
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleChange = (value) => {
    if (
      value.length === 0 ||
      (typeof value === "string" && /^\d+$/.test(value))
    ) {
      setOtp(value);
    }
  };

  const register = async (e) => {
    e.preventDefault();
    if (!email) {
      setError("Please fill the required details correctly");
    } else if (!validateEmail(email)) {
      setError("Please enter Valid Email");
    } else if (!password || password.length < 8) {
      setError("Please fill the required details correctly");
    } else if (!confirmPassword || confirmPassword.length < 8) {
      setError("Please fill the required details correctly");
    } else if (
      !/[A-Z]/.test(password) ||
      !/[a-z]/.test(password) ||
      !/[0-9]/.test(password) ||
      !/[!@#$%^&*(),.?":{}|<>]/.test(password)
    ) {
      setError("Password must be in the given format");
    } else if (password !== confirmPassword) {
      setError("Both passwords should match");
    } else if (!toggle) {
      setError("Please accept the terms and conditions");
    } else {
      try {
        setError("");
        const data = { email, type: "signup" };
        console.log("OTP: ");
        const res = await userData.sendOTP(data);
        console.log("OTP: ");
        if (res.data.error) {
          setLoginButton(true);
        } else if (res.data.error_2) {
          setLoginButton(false);
          setError3(res.data.error_2);
        } else {
          setLoginButton(false);
          setOTPModal(true);
          startTimer();
        }
      } catch (err) {
        console.log('4%34' , err);
        setError(err.response?.data?.message || "Something went wrong");
      }
    }
  };

  const completeRegistration = async () => {
    setError2('');
    try {
      const checkOtp = await userData.verifyOtp({ email, otp, type: "signup" });
      console.log(checkOtp);
      if(!checkOtp.data.success){
        setError2("Incorrect OTP!");
        return;
      }
      setError2("");
      const data = { email, password };
      const res = await userData.signup(data);
      console.log(res);
      if (res.data.message === "success") {
        setRegistration(true);
        redirectToLoginPage();
      } else if (res.data?.verificationStatus) {
        setVerificationStatus(res.data.verificationStatus);
        setShowDialog(true);
      }
      if (res.data.error) {
        setError2(res.data.error);
      }
    } catch (err) {
      console.log(err);
      setError2(err.response?.data?.message || "Something went wrong");
    }
  };

  const redirectToLoginPage = () => {
    setTimeout(() => {
      navigate("/login");
    }, 3000);
  };
  const startTimer = () => {
    setTimer(60);
    const id = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer > 0) {
          return prevTimer - 1;
        } else {
          clearInterval(id);
          return 0;
        }
      });
    }, 1000);
    setIntervalId(id);
  };

  const resendOtp = async (e) => {
    e.preventDefault();
    const data = { email, type: "signup" };
    try {
      const res = await userData.resendOtp(data);
      if (res.data.error) {
        setError2(res.data.error);
      }
      console.log(res);
      startTimer();
    } catch (err) {
      console.log(err);
    }
  };

  const requestAccess = async () => {
    try {
      setLoading(true);
      const res = await userData.requestAccess(email, password);
      if (res.data.success === true) {
        setVerificationStatus("pending");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  function matchIsNumeric(text) {
    const isNumber = typeof text === "number";
    const isString = typeof text === "string" || text === "";
    return (isNumber || (isString && text !== "")) && !isNaN(Number(text));
  }

  const validateChar = (value, index) => {
    return matchIsNumeric(value);
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <div className="min-w-[100vw] min-h-[100vh] flex justify-center items-center md:grid grid-cols-2 overflow-y-auto overflow-x-hidden  ">
      <div className="rounded-2xl overflow-hidden primary-shadow">
        <img src="/lady-tech.png" className=" hidden md:block scale-[0.8]" />
      </div>
      {!verificationStatus && (
        <div
          className="bg-white px-5 sm:px-10 nmd:px-20 py-10 flex flex-col gap-5 "
        >
          <div className="flex flex-col items-center ">
            <p className="font-bold text-3xl">Sign Up</p>
            <p className=" text-secondary-text">Register with us</p>
          </div>

          <div className="flex flex-col gap-5">
            <TextField
              disabled={OTPModal}
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              variant="outlined"
            />
            <div className="w-full relative">
              <div className="absolute right-2 top-4 cursor-pointer z-10 bg-white">
                {!hide && (
                  <IoEyeOffOutline size={25} onClick={() => setHide(!hide)} />
                )}
                {hide && (
                  <IoEyeOutline size={25} onClick={() => setHide(!hide)} />
                )}
              </div>
              <TextField
                disabled={OTPModal}
                label="Password"
                variant="outlined"
                type={hide ? "password" : "text"}
                className="w-full"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <p className="text-sm">
              <span className="text-red-500 mr-1">Note:-</span>Password must be
              at least 8 characters long and contain at least one uppercase
              letter, one lowercase letter, one number and one special character
            </p>
            <div className="w-full relative">
              <div className="absolute right-2 top-4 cursor-pointer z-10 bg-white">
                {!hide && (
                  <IoEyeOffOutline size={25} onClick={() => setHide(!hide)} />
                )}
                {hide && (
                  <IoEyeOutline size={25} onClick={() => setHide(!hide)} />
                )}
              </div>
              <TextField
                disabled={OTPModal}
                label="Confirm Password"
                variant="outlined"
                type={hide ? "password" : "text"}
                className="w-full"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
          </div>

          <div className="flex justify-center items-center">
            <Switch
              value={toggle}
              disabled={OTPModal}
              onChange={() => setToggle(!toggle)}
              {...label}
            />
            <p className="">I accept the Terms and Conditions</p>
          </div>

          {error && <p className="error">{error}</p>}

          {loginButton && (
            <>
              <div className="flex gap-3 items-center justify-between">
                <p className="error">Email already registered</p>
                <btn
                  className="primary-button"
                  onClick={() => navigate("/login")}
                >
                  LOGIN
                </btn>
              </div>
            </>
          )}

          {error3 && <p className="error">{error3}</p>}

          <Divider />
          {!OTPModal && (
            <button className='primary-button' onClick={register}>Continue</button>
          )}
          {OTPModal && (
            <>
              <h3 className="text-center font-semibold text-lg">
                Verify Your Account
              </h3>
              <div className="text-body-2 text-secondary-text">
                We sent a verification code to{" "}
                <span className="underline italic font-semibold">{email}</span>{" "}
                <br />
                Please check your email and type the code below
              </div>
              <div>
                <MuiOtpInput
                  value={otp}
                  onChange={handleChange}
                  length={5}
                  validateChar={validateChar}
                />
              </div>
              {error2 && <div className="error">{error2}</div>}
              <p className="text-secondary-text italic">
                Did not receive the code?{" "}
                <button
                  className={` px-3 py-1 rounded-full underline text-blue-700 ${
                    timer > 0 && "text-gray-500"
                  }`}
                  onClick={resendOtp}
                  disabled={timer > 0}
                >
                  Resend
                </button>{" "}
                <span className="ml-1">
                  {timer ? "in " + timer + " s" : ""}
                </span>
              </p>
              <button className="primary-button" onClick={completeRegistration}>
                Register
              </button>
            </>
          )}
          <div className="flex gap-1 items-center">
            <p>Already have an account?</p>
            <Link to="/login" className=" text-blue-600 underline ">
              Login
            </Link>
          </div>
        </div>
      )}
      {verificationStatus === "not found" && (
        <div className="flex flex-col gap-5 p-4">
          <div>
            <p className="font-bold text-xl">Would you like to try PARINAAM?</p>
            <p className="text-secondary-text font-semibold">
              Access to our trial version is limited. Please click on Request
              Access button below to apply for free trial access.
            </p>
          </div>
          <button
            className="primary-button"
            onClick={requestAccess}
            disabled={loading}
          >
            Request Access
          </button>
        </div>
      )}
      {verificationStatus === "pending" && (
        <div className="flex flex-col gap-5 p-4">
          <p className="font-bold text-xl">
            We have received your registration request
          </p>
          <p className="text-secondary-text font-semibold">
            Our team will verify your account and grant access accordingly. You
            will receive further communication via email.
          </p>
          <button className="secondary-button" onClick={() => navigate("/")}>
            Go To Home
          </button>
        </div>
      )}
    </div>
  );
}

export default Signup;

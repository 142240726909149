import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router";
import * as d3 from "d3"; // Add this line to import d3
import userData from "../services/userData";
import Markdown from "react-markdown";
import { IoMdStar } from "react-icons/io";
import "./ResultEvaluate.css";
import SpiderMap from "../components/SpiderMap";
import { MdClose, MdDownload } from "react-icons/md";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import AppuComponent from "../components/AskAppu";
import {
  FaArrowRight,
  FaCircle,
  FaComment,
  FaCross,
  FaGreaterThan,
  FaMapLocation,
} from "react-icons/fa6";
import { MdOutlineExpandMore } from "react-icons/md";
import ScoreBar from "../components/scoreBar";
import Heatmap from "../components/Heatmap";

export const DividerComponent = () => {
  return <div className="my-8 h-[1px] bg-gray-200 w-full" />;
};

function CardRender({ title, description, id, clickHandler }) {
  return (
    <div className="px-4 py-3 rounded-lg primary-shadow flex flex-col gap-5 justify-between items-start border-2">
      <div className="">
        <p className="text-lg font-bold">
          {title.charAt(0).toUpperCase() + title.slice(1)}
        </p>
        <p className="text-secondary-text">{description}</p>
      </div>
      <div className="flex justify-end w-full">
        <Button
          endIcon={<FaArrowRight size={15} />}
          onClick={() => clickHandler(id)}
          variant="contained"
          color="success"
        >
          Details
        </Button>
      </div>
    </div>
  );
}

function EvaluationResult() {
  const [score, setScore] = useState(
    JSON.parse(localStorage.getItem("matrix_score"))
  );
  const [gaps, setGaps] = useState(JSON.parse(localStorage.getItem("gaps")));
  const [overallScore, setOverallScore] = useState(
    Math.ceil(JSON.parse(localStorage.getItem("overall_score")))
  );
  const md = {};
  const [suggestions, setSuggestions] = useState(
    JSON.parse(localStorage.getItem("suggestions"))
  );
  const [suggestionKey] = useState(["Short Term", "Mid Term", "Long Term"]);
  const [arr] = useState(["experience", "skills", "qualifications"]);
  const [arr2] = useState(["quantity", "relevance", "quality"]);
  const [gapsRenderingUtility] = useState([
    {
      0: "Experience",
      1: "experience_gap",
      2: "Experience",
      4: "Brief description",
      5: "gaps-experience",
    },
    {
      0: "Skills",
      1: "skills_gap",
      2: "Skills",
      4: "Brief description",
      5: "gaps-skills",
    },
    {
      0: "qualification",
      1: "qualifications_gap",
      2: "Qualifications",
      4: "Brief description",
      5: "gaps-qualifications",
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState(0);
  const [appuEnable, setAppuEnable] = useState(false);
  const [summarizedText, setSummarizedText] = useState(null);
  const [showFeedback, setShowFeedback] = useState(0);
  const [cvDialog, setCvDialog] = useState(false);
  const [downloadId, setDownloadId] = useState("");
  const navigate = useNavigate();

  const [showAppu, setShowAppu] = useState(false);

  const evaluationText = useMemo(() => {
    const text =
      (gaps?.Experience?.experience_gap || "") +
      (gaps?.Skills?.skills_gap || "") +
      (gaps?.qualification?.qualifications_gap || "");
    return text;
  }, [gaps]);

  const getAppuSpeech = async () => {
    try {
      if (summarizedText) return;
      const res = await userData.summarizeText(evaluationText);
      const preText =
        "Thank you for asking me, after carefully inspecting your CV, I have some points to make.";
      const postText = "";
      setSummarizedText(preText + res.data?.summary + postText);
    } catch (err) {
      console.log(err);
    }
  };

  const capitalize = (s) => {
    if (!s) return "";
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
  };

  const GenerateMarkdown = ({ str }) => {
    return (
      <div
        className={`prose max-w-full border-2 border-primary-bold/10 p-4 rounded-md my-4 `}
      >
        <Markdown>{str}</Markdown>
      </div>
    );
  };

  const toggleMode = () => {
    setMode(!mode);
  };

  function changeKey(arr) {
    arr[1] = arr[1].charAt(0).toLowerCase() + arr[1].slice(1).toLowerCase();
    return arr;
  }

  const [mapOpen, setMapOpen] = useState(false);

  const mapRef = useRef(null);

  function generateHeatmap(container) {
    console.log("Generating Heatmap", container);
    const data = [
      { row: "Quantity", col: "Qualification", value: score[3] },
      { row: "Quantity", col: "Skills", value: score[0] },
      { row: "Quantity", col: "Experience", value: score[6] },
      { row: "Relevance", col: "Qualification", value: score[4] },
      { row: "Relevance", col: "Skills", value: score[1] },
      { row: "Relevance", col: "Experience", value: score[7] },
      { row: "Quality", col: "Qualification", value: score[5] },
      { row: "Quality", col: "Skills", value: score[2] },
      { row: "Quality", col: "Experience", value: score[8] },
    ];

    d3.select(container).select("#heatmap").html("");
    d3.select(container).select("#legend").html("");

    const margin = { top: 30, right: 30, bottom: 70, left: 100 },
      width = 600 - margin.left - margin.right,
      height = 400 - margin.top - margin.bottom;

    const svg = d3
      .select(container)
      .select("#heatmap")
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const xLabels = Array.from(new Set(data.map((d) => d.col)));
    const yLabels = Array.from(new Set(data.map((d) => d.row)));

    const xScale = d3
      .scaleBand()
      .domain(xLabels)
      .range([0, width])
      .padding(0.1);

    const yScale = d3
      .scaleBand()
      .domain(yLabels)
      .range([height, 0])
      .padding(0.1);

    const colorScaleRed = d3
      .scaleLinear()
      .domain([40, 0])
      .range(["#ffE6E6", "#ff3333"]);

    const colorScaleGreen = d3
      .scaleLinear()
      .domain([40, 100])
      .range(["#E6FFE6", "#339933"]);

    const tooltip = d3
      .select(".heat")
      .append("div")
      .attr("class", "tooltip")
      .style("display", "none");

    svg
      .selectAll()
      .data(data)
      .enter()
      .append("rect")
      .attr("x", (d) => xScale(d.col))
      .attr("y", (d) => yScale(d.row))
      .attr("width", xScale.bandwidth())
      .attr("height", yScale.bandwidth())
      .attr("class", "cell")
      .style("fill", (d) =>
        d.value < 40 ? colorScaleRed(d.value) : colorScaleGreen(d.value)
      )
      .on("mouseover", function (event, d) {
        d3.select(this).style("stroke", "white").style("stroke-width", "5");

        // Highlight the corresponding row and column labels
        d3.selectAll(".x-axis .tick text")
          .filter(function (label) {
            return label === d.col;
          })
          .style("font-weight", "bold")
          .style("fill", "#F4BF43");

        d3.selectAll(".y-axis .tick text")
          .filter(function (label) {
            return label === d.row;
          })
          .style("font-weight", "bold")
          .style("fill", "#F4BF43");

        const cellPosition = this.getBoundingClientRect();
        const tooltipHeight = 35; // Adjust if needed

        tooltip.transition().duration(50).style("opacity", 0.9);

        tooltip
          .html(`<strong>SCORE: ${d.value}</strong>`)
          .style(
            "left",
            cellPosition.left +
              window.scrollX +
              cellPosition.width / 2 -
              50 +
              "px"
          )
          .style(
            "top",
            cellPosition.top + window.scrollY - tooltipHeight + 63 + "px"
          )
          .style("position", "absolute")
          .style("text-align", "center")
          .style("width", "100px")
          .style("margin-inline", "auto")
          .style("height", "25px")
          .style("padding", "5px")
          .style("font", "14px sans-serif")
          .style("background", "lightsteelblue")
          .style("color", "white")
          .style("border", "0px")
          .style("border-radius", "8px")
          .style("pointer-events", "none")
          .style("transition", "opacity 0.1s ease")
          .style("white-space", "nowrap") // Prevent text wrap
          .style("display", "block")
          .style("box-shadow", "0px 0px 10px rgba(0, 0, 0, 0.5)");

        arrow
          .attr(
            "transform",
            `translate(${legendWidth}, ${legendScale(d.value)})`
          )
          .style("opacity", 1);
      })
      .on("mouseout", function (event, d) {
        d3.select(this).style("stroke-width", 2);

        d3.selectAll(".x-axis .tick text")
          .filter(function (label) {
            return label === d.col;
          })
          .style("font-weight", null)
          .style("fill", null);

        d3.selectAll(".y-axis .tick text")
          .filter(function (label) {
            return label === d.row;
          })
          .style("font-weight", null)
          .style("fill", null);

        tooltip.transition().duration(50).style("opacity", 0);
        arrow.style("opacity", 0);
      });

    svg
      .append("g")
      .attr("class", "x-axis")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(xScale).tickSize(0).tickPadding(10))
      .selectAll("text")
      .attr("dy", "1em")
      .attr("class", "axis-label")
      .style("font-size", "15px");

    svg
      .append("g")
      .attr("class", "y-axis")
      .call(d3.axisLeft(yScale).tickSize(0).tickPadding(10))
      .selectAll("text")
      .attr("dx", "-1em")
      .attr("class", "axis-label")
      .style("font-size", "15px");

    // Hide axis lines
    svg.selectAll(".domain").attr("stroke", "none");

    const legendHeight = 210,
      legendWidth = 20;

    const legendSvg = d3
      .select(container)
      .select("#legend")
      .append("svg")
      .attr("width", legendWidth + 50)
      .attr("height", legendHeight + 50)
      .append("g")
      .attr("transform", "translate(20, 20)");

    const legendScale = d3
      .scaleLinear()
      .domain([0, 100])
      .range([legendHeight, 0]);

    const legendAxis = d3.axisRight(legendScale).ticks(5);

    const gradient = legendSvg
      .append("defs")
      .append("linearGradient")
      .attr("id", "legend-gradient")
      .attr("x1", "0%")
      .attr("y1", "100%")
      .attr("x2", "0%")
      .attr("y2", "0%");

    gradient.append("stop").attr("offset", "0%").attr("stop-color", "#ff0000");
    gradient.append("stop").attr("offset", "30%").attr("stop-color", "#ffcccc");
    gradient.append("stop").attr("offset", "40%").attr("stop-color", "#ccffcc");
    gradient
      .append("stop")
      .attr("offset", "100%")
      .attr("stop-color", "#008000");

    legendSvg
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", legendWidth)
      .attr("height", legendHeight)
      .style("fill", "url(#legend-gradient)");

    legendSvg
      .append("g")
      .attr("transform", `translate(${legendWidth}, 0)`)
      .call(legendAxis)
      .selectAll(".domain")
      .remove();

    legendSvg.selectAll(".tick line").attr("stroke", "none");

    const arrow = legendSvg
      .append("polygon")
      .attr("points", "-10,0 10,0 0,10")
      .attr("fill", "black")
      .style("opacity", 0);
  }

  useEffect(() => {
    console.log("Entered", mapOpen);
    console.log(mapRef.current);
    if (mapOpen && mapRef.current) {
      console.log("Generation initiated");
      generateHeatmap(mapRef.current);
    }
  }, [mapOpen]);

  const downloadEvalReport = async () => {
    try {
      const gapsArr = ["Experience", "Skills", "Qualifications"];
      const arr = ["quantity", "relevance", "quality"];
      const suggArr = ["Short Term", "Mid Term", "Long Term"];
      const arr_2 = ["experience", "skills", "qualifications"];
      const arr_3 = ["Quantity", "Relevance", "Quality"];

      gaps.Qualifications = gaps.qualification;
      gaps.Experience.conclusion = gaps.Experience.experience_gap;
      gaps.Skills.conclusion = gaps.Skills.skills_gap;
      gaps.Qualifications.conclusion = gaps.Qualifications.qualifications_gap;

      const data = {
        score,
        gaps,
        overall_score: overallScore,
        suggestions,
        gapsArr,
        arr,
        suggArr,
        arr_2,
        arr_3,
      };

      setLoading(true);
      const response = await userData.getEvaluationReport(data);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "evaluation_report.pdf");
      document.body.appendChild(link);
      link.click();
      link.remove();
      setCvDialog(false);
      setDownloadId("");
    } catch (err) {
      console.log("Error while downloading CV", err);
    } finally {
      setLoading(false);
    }
  };
  const [open, setOpen] = useState(null);

  const [result, setResult] = useState({});

  useEffect(() => {
    if (open) {
      const arr = open.split("-");

      if (arr[0] === "gaps") {
        console.log("here");
        if (arr[1] === "experience") {
          console.log("here too");
          console.log({
            section: "gaps",
            index: 0,
            obj: gapsRenderingUtility[0],
          });
          setResult({
            section: "gaps",
            index: 0,
            obj: gapsRenderingUtility[0],
          });
        } else if (arr[1] === "skills") {
          setResult({
            section: "gaps",
            index: 1,
            obj: gapsRenderingUtility[1],
          });
        } else if (arr[1] === "qualifications") {
          setResult({
            section: "gaps",
            index: 2,
            obj: gapsRenderingUtility[2],
          });
        }
      } else if (arr[0] === "suggestions") {
        if (arr[1] === "short") {
          setResult({
            section: "suggestions",
            key: suggestionKey[0],
            index: 0,
          });
        } else if (arr[1] === "medium") {
          setResult({
            section: "suggestions",
            key: suggestionKey[1],
            index: 1,
          });
        } else if (arr[1] === "long") {
          setResult({
            section: "suggestions",
            key: suggestionKey[2],
            index: 2,
          });
        }
      }

      console.log(result);
    }
  }, [open]);

  if (!gaps) return null;

  return (
    <div className="flex flex-col gap-12 pb-20 pl-5">
      <ScoreBar score={overallScore} />

      <div className="mx-auto w-full">
        <div className="px-7">
          <div className="grid grid-cols-3 gap-6 py-3 ">
            <Button
              disabled={loading}
              onClick={downloadEvalReport}
              color="primary"
              variant="contained"
            >
              <p>Download report</p>
              <MdDownload size={20} />
            </Button>
            <Button
              onClick={() => {
                getAppuSpeech();
                setShowAppu(true);
              }}
              variant="contained"
              endIcon={<FaComment />}
              color="secondary"
            >
              Ask Appu
            </Button>
            <Button
              onClick={() => {
                setMapOpen(true);
              }}
              variant="contained"
              color="success"
              endIcon={<FaMapLocation />}
            >
              Profile maps
            </Button>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4 border-2 border-dashed border-primary/20 rounded-lg py-10 p-5">
        <div>
          <p className="text-xl font-bold text-primary-bold underline">
            Human in loop evaluation
          </p>
        </div>

        <div>
          <p className="text-lg font-bold">CV Gaps</p>
          <div className="grid grid-cols-3 px-4 gap-5 ">
            {gapsRenderingUtility.map((gap, index) => {
              const title = gap[0];
              const description = gap[4];
              const id = gap[5];
              return (
                <CardRender
                  key={index}
                  clickHandler={(e) => setOpen(e)}
                  id={id}
                  description={description}
                  title={title}
                />
              );
            })}
          </div>
        </div>

        <div>
          <p className="text-lg font-bold">Suggestions</p>
          <div className="grid grid-cols-3 gap-5 px-4 ">
            {["short", "medium", "long"].map((term, index) => {
              const id = `suggestions-${term}`;
              const title =
                term.charAt(0).toUpperCase() + term.slice(1) + "-term";
              const description = "Brief suggestions";
              return (
                <CardRender
                  key={index}
                  clickHandler={(e) => setOpen(e)}
                  id={id}
                  description={description}
                  title={title}
                />
              );
            })}
          </div>
        </div>

        <div>
          <Accordion>
            <AccordionSummary
              style={{ fontWeight: 700, fontSize: "18px" }}
              expandIcon={<FaArrowRight />}
            >
              Evaluation strategy
              <span className="ml-2 text-base font-semibold text-secondary-text">
                (brief overview)
              </span>
            </AccordionSummary>
            <AccordionDetails>
              Our strategy involves a 3x3 matrix where we assess the quality,
              relevance, and quantity of a candidate’s experience, skills, and
              qualifications. We evaluate the depth and impact of their past
              roles, ensure their skills directly match the job requirements,
              and consider the level and number of relevant qualifications they
              possess. This structured approach ensures a thorough and objective
              evaluation, focused on aligning the candidate’s profile with the
              specific demands of the job role.
            </AccordionDetails>
          </Accordion>
        </div>

        <Dialog
          open={showAppu}
          onClose={() => setShowAppu(false)}
          maxWidth={false}
          className="w-[90vw] mx-auto"
        >
          <DialogTitle className="font-semibold text-primary-bold text-center">
            Hi, I am Appu!
          </DialogTitle>
          <DialogContent className="">
            <AppuComponent text={summarizedText} />
          </DialogContent>
        </Dialog>

        <Dialog open={Boolean(open)} onClose={() => setOpen(null)}>
          <DialogContent>
            {result?.section === "gaps" && result?.obj && (
              <div>
                <div>
                  <p className="text-lg mt-4 font-bold">
                    {result.index + 1}
                    {")"} <span>{result.obj[2]}</span>
                  </p>
                  <ul>
                    {arr2.map((k, i) => {
                      return (
                        <li key={i} className="ml-5 mt-4">
                          <div className="flex gap-1 items-center">
                            <FaCircle size={7} />
                            <strong>{capitalize(k)}</strong>
                          </div>
                          <GenerateMarkdown str={gaps[result.obj[0]][k]} />
                        </li>
                      );
                    })}
                  </ul>

                  <div>
                    <strong>Conclusion</strong>
                    <GenerateMarkdown
                      str={gaps[result.obj[0]][result.obj[1]]}
                    />
                  </div>
                </div>
                ;
              </div>
            )}
            {result.section === "suggestions" && result.key && (
              <div key={result.index}>
                <div className="flex gap-1 items-center mt-2 text-xl">
                  <strong>
                    <span>
                      {result.index + 1}
                      {") "}
                    </span>
                    <span>
                      {changeKey(result.key.split(" ")).join("-")}
                      {" suggestions"}
                    </span>
                  </strong>
                </div>
                <div className="ml-5 mt-5">
                  {arr.map((k, i) => {
                    return (
                      <div key={i}>
                        <div className="flex gap-1 items-center">
                          <FaCircle size={7} />

                          <strong>{capitalize(k)}</strong>
                        </div>
                        <GenerateMarkdown str={suggestions[result.key][k]} />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </DialogContent>
        </Dialog>
      </div>
      <Dialog
        open={mapOpen}
        onClose={() => setMapOpen(false)}
        className={`fixed mx-auto primary-shadow bg-white top-0 overflow-auto w-[100vw] h-[100vh] transition duration-300`}
      >
        <MdClose size={30} onClick={() => setMapOpen(false)} />
        <DialogContent>
          <Heatmap />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default EvaluationResult;
